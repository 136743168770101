/* eslint-disable */
import React, { Component, Fragment } from 'react'
import { getColors, setStep, setActualTrackbarPeriod} from '../../actions/tracking_data';
import { createInfo } from '../../actions/infos';
import { APIFactory } from "../common/API"

import moment from "moment";

import {
  Col,
  Row,
  Form,
  Button,
  Container
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import SpinnerWintics from "../common/SpinnerWintics";
import CheckboxWintics from "../common/CheckboxWintics";
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux'
import { ResponsiveBar } from '@nivo/bar'
import { ZONE_NUMBER_OFFSET } from '../common/Constants'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as Excel from "exceljs/dist/exceljs.min.js";
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from "react-i18next";
import { isLegacyEdge } from "react-device-detect";

var index = 'timestamp'

const wintics_classes_colormap_bgr = {
  '0': [255, 0, 0],    // Classe inconnue
  'person': [255, 0, 204],  // Personne
  'bicycle': [131, 250, 59], // Vélo
  'car': [255, 191, 0],  // VL
  'motorcycle': [0, 128, 0],    // 2RM
  'van': [0, 0, 255],   // Van
  'truck': [0, 0, 0],      // PL
  'bus': [0, 255, 255]  // Bus
}


const ticksFormmater = (value, data) => {
  const valueIndex = data.map(i => i.timestamp).indexOf(value);
  const data_len = data.length

  if (valueIndex % ((Math.ceil(data_len / 15) * Math.floor(value.length / 4))) === 0) {
    return value;
  }
  return "";
};

const get_color = (class_label) => {
  return colors[class_label['id']]
}
let colors = {
  '0': "rgb(0, 0, 255)",    // Classe inconnue
  'person': "rgb(204, 0,255 )",  // Personne
  'bicycle': "rgb(59, 250,131 )", // Vélo
  'car': "rgb(0, 191,255 )",  // VL0
  'motorcycle': "rgb(0, 128, 0)",    // 2RM
  'van': "rgb(255, 0,0 )",   // Van
  'truck': "rgb(0, 0, 0)",      // PL
  'bus': "rgb(255, 255, 0)"  // Bus
};
const TrackResponsiveBar = ({ data, keys, index, total, max_ticks = 5,
  max_value, graph_height = 220, t}) => {

  return <ResponsiveBar
    data={data}
    keys={keys}
    height={graph_height}
    indexBy={index}
    margin={{ top: 50, right: 48.25, bottom: 40, left: 39.14 }}
    padding={data.length === 1 ? 0.8 : data.length === 2 ? 0.5 : data.length === 3 ? 0.3 : 0.1}
    colors={{ scheme: 'paired' }}
    theme={{
      axis: {
        ticks: {
          text: {
            fill: "white",
            fontWeight: "bold"
          }
        },
        legend: {
          text: {
            fill: "white"
          }
        }
      },
      tooltip: {
        container: {
          background: 'var(--fill_2)',
        }
      }
    }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: '#38bcb2',
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#eed312',
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }
    ]}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    enableGridY={false}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 0,
      tickPadding: isLegacyEdge ? 15 : 5,
      tickRotation: 0,
      legend: null,
      legendPosition: 'middle',
      legendOffset: 32,
      format: value => ticksFormmater(value, data)
    }}
    axisLeft={{
      tickSize: 0,
      tickPadding: 2,
      tickRotation: 0,
      legend: null,
      legendPosition: 'middle',
      legendOffset: -10,
      format: () => null,
    }}
    axisTop={{
      tickSize: 0,
      tickPadding: 5,
      itemWidth: 80,
      itemSpacing: 5,
      tickRotation: 0,
      format: (d) => <tspan

        y={graph_height - 90 - (total[d] / max_value) * (graph_height - 90)} fontWeight="bold">
        {data.length <= max_ticks && total[d] > 0 ? data.length <=
          (max_ticks - 5) ? numberWithSpaces(total[d]) : total[d] : null}
      </tspan>,

    }}
    enableGridY={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelFormat={(d) => <tspan>{data.length <= (max_ticks - 5) ? numberWithSpaces(d) : d}</tspan>}
    labelTextColor="var(--fill_1)"
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'top',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: -42,
        itemsSpacing: 2,
        itemWidth: 60,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 14,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ],
        itemTextColor: "white"
      }
    ]}
    tooltip={({ id, value, color, data, indexValue }) => (
      <strong style={{ color }} >
        {t('path')}: {id}<br />
        {t('number')}/{t('total')}: {numberWithSpaces(value)} / {numberWithSpaces(total[indexValue])}<br />
        {t('percentage')}: { (value * 100 / total[indexValue]).toFixed(0)}%<br />
        {t('total')}: {indexValue}
      </strong>
    )}

    animate={false}
    motionStiffness={90}
    motionDamping={15}
  />
}

const exportToCSV = (csvData, total, columns, fileName) => {
  var workbook = new Excel.Workbook();
  var worksheet = workbook.addWorksheet('Tracking Data');
  var header_cols = columns.map(el => { return { header: el, key: el, width: 10 } })
  var totals_columns = { "total": 0 }
  columns.forEach(column => {
    totals_columns[column] = 0
  }
  )
  Object.values(total).forEach(v => totals_columns["total"] += v)

  totals_columns["timestamp"] = "Full Period"
  worksheet.columns = [
    { header: 'Datetime', key: 'timestamp', width: 10 }
  ].concat(header_cols).concat([{ header: 'Total', key: 'total', width: 10 }]);
  csvData.forEach(row => {

    Object.keys(row).forEach(key => {
      if (key != "timestamp")
        totals_columns[key] += row[key]
    })

    worksheet.addRow({ ...row, total: total[row.timestamp] });
  })

  worksheet.addRow(totals_columns)



  workbook.xlsx.writeBuffer().then(function (data) {
    var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    FileSaver.saveAs(blob, fileName + ".xlsx");
  });

}
const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
const periods_to_seconds = { "hours": 3600, "days": 86400, "weeks": 604800, "years": 31536000 }


function getKeysAndZonesOutOfTracks(tracks) {
  let keys = new Set()
  let zones_in = new Set()
  let zones_out = new Set()
  let len = tracks.length
  tracks.forEach((element, index) => {
    keys.add(element.zone_in + '->' + element.zone_in)
    zones_in.add(element.zone_in)
    zones_out.add(element.zone_out)
    if (index === len - 1)
      return [keys, new Set(Array.from(zones_in).sort()), new Set(Array.from(zones_out).sort())]
  })
}

class TrackBarPaths extends Component {
  constructor(props) {
    super(props)
    let keys = new Set()

    let zones_in = props.tracks.map(tracks => new Set())
    let zones_out = props.tracks.map(tracks => new Set())
    let len = props.tracks.length

    props.tracks.forEach((tracks, idx) =>
      tracks.forEach((element) => {
        keys.add(element.zone_in + '->' + element.zone_out)
        zones_in[idx].add(element.zone_in)
        zones_out[idx].add(element.zone_out)
      }))


    var default_step = periods_to_seconds[this.props.tracking_data.step]

    var default_step_seconds = default_step % 60
    var default_step_minutes = Math.floor((default_step % 3600) / 60)
    var default_step_hours = Math.floor((default_step % 86400) / 3600)
    var default_step_days = Math.floor(default_step / periods_to_seconds["days"])
    var default_step_weeks = Math.floor(default_step / periods_to_seconds["weeks"])
    var default_step_years = Math.floor(default_step / periods_to_seconds["years"])


    this.state = {
      tracks: props.tracks,
      data: [],
      keys: keys,
      select_keys: Array.from(keys).map(key => { return { "label": key, "value": key } }),
      selected_keys: Array.from(keys).map(key => { return { "label": key, "value": key } }),
      zones_in: zones_in.map(zones_in_device => Array.from(zones_in_device).sort()),
      zones_out: zones_out.map(zones_in_device => Array.from(zones_in_device).sort()),
      selected_zones_in: zones_in,
      selected_zones_out: zones_out,
      // counts_barchart: counts_barchart,
      step: default_step,
      stepSeconds: default_step_seconds,
      stepMinutes: default_step_minutes,
      stepHours: 0,
      stepDays: default_step_days,
      stepWeeks: default_step_weeks,
      stepYears: default_step_years,
      start: this.props.start,
      end: this.props.end,
      max_bars: this.props.max_bars ? this.props.max_bars : 624,
      loaderActive: true,
      step_filters: ["hours", "days", "weeks", "years"],
      selected_step_filters: new Set([this.props.tracking_data.step]),
      total: 0,
      min_: 0,
      max_: 0,
      mean_: 0,
      sum_: 0
    }
  }
  componentDidMount() {


    let data = this.prepareTrackingData(this.state.tracks, this.state.step, moment(this.state.start).format("X"),
      moment(this.state.end).format("X")
      , this.state.keys
      , this.state.selected_zones_in
      , this.state.selected_zones_out
      , true)
  }
  initializeData = (key_set, start, end, step, tracking_data) => {
    let data = [];
    let format = "";
    let s = "d";
    if (step === 86400) {
      format = "DD/MM";
      s = "d";
    } else if (step === 3600) {
      format = "DD/MM HH:mm";
      s = "h";
    } else if (step === 31540000 || step === 31626400) {
      format = "YYYY";
      s = "y";
    } else if (step === 604800) {
      format = "DD/MM";
      s = "w";
    } else format = "DD/MM HH:mm";
    if (s === "d") {
      data.push({});
      key_set.forEach((key) => {
        data[0][key] = 0;
      });
      data[0]["timestamp"] = moment.unix(start)
      .utcOffset(tracking_data[0][tracking_data[0].length - 1]["tz"])
      .format(format);
    }
    if (tracking_data && tracking_data.length > 0) {
      for (var i = s === "d" ? 1 : 0; i < end; i++) {
        data.push({});
        key_set.forEach((key) => {
          data[i][key] = 0;
        });
          data[i]["timestamp"] = moment
            .unix(start)
            .add(i, s)
            .utcOffset(tracking_data[0][tracking_data[0].length - 1]["tz"])
            .format(format);
      }
    }

    return data;
  };
  transformData = (data, tracking_data, start, end, key_set, selected_zones_in, selected_zones_out, step, set_state) => {
    tracking_data.forEach((tracks, index_tracks) => {
      let len = tracks.length
      tracks.forEach((element, ind) => {
        // const timestamp = moment(element['timestamp']).format("X")
        let timestamp = element['timestamp']

        const category = element.zone_in + '->' + element.zone_out
        let actual_step = step
        if( step == periods_to_seconds['years'] &&
          moment.unix(timestamp).isLeapYear()
        ){
          actual_step = step + periods_to_seconds['days']
        }
        if (timestamp <= end && timestamp >= start && key_set.has(category)
          && (!selected_zones_in || selected_zones_in.length <= index_tracks || selected_zones_in[index_tracks]
            && selected_zones_in[index_tracks].has(element["zone_in"]))

          && (!selected_zones_out[index_tracks]
            || selected_zones_out.length <= index_tracks ||
            selected_zones_out[index_tracks]
            && selected_zones_out[index_tracks].has(element["zone_out"]))) {

          const index = Math.floor((timestamp - start) / actual_step)
          data[index][category] ? data[index][category] += 1 : data[index][category] = 1
        }
        // TODO move this zones_in
        if ((ind === len - 1) && (set_state === true)) {
          const total = {}
          data.map(d => {
            total[d[index]] = 0;
            Array.from(key_set).map(k => {
              total[d[index]] += d[k]
            })
          })
          const [mean_, sum_, min_, max_] = this.prepareNumbers(total)
          this.setState({
            total: total,
            data: data,
            mean_: mean_,
            sum_: sum_,
            min_: min_,
            max_: max_,
            loaderActive: false,
          })
        }
      })
    })
    return data
  }
  getActualStart(start1, tracking_data) {
    let min_real_start = this.state.start;
    tracking_data.forEach(tracks => {
      min_real_start = Math.min(min_real_start, tracks[0] ? tracks[0].timestamp : min_real_start)
    }
    )
    let timezone = "+01:00"
    if (tracking_data && tracking_data.length && tracking_data[0].length){
      timezone = tracking_data[0][0].tz
    }
    let max_start = Math.max(start1, min_real_start)
    let actual_start;
    if (this.state.step === periods_to_seconds['days']) {
      actual_start = moment.unix(max_start).utcOffset(timezone).startOf('day').format('X')
    } else if (this.state.step === periods_to_seconds['hours']) {
      actual_start = moment.unix(max_start).utcOffset(timezone).startOf('hour').format('X')
    } else if (this.state.step === periods_to_seconds['years']) {
      actual_start = moment.unix(max_start).utcOffset(timezone).startOf('year').format('X')
    } else if (this.state.step === periods_to_seconds['weeks']) {
      actual_start = moment.unix(max_start).utcOffset(timezone).startOf('week').format('X')
    }
    else {
      actual_start = max_start
    }
    return actual_start
  }
  getActualEnd(end, actual_start, step, tracking_data) {
    if (tracking_data && tracking_data.length > 0 && tracking_data[0].length > 0) {
      let max_real_end = tracking_data[0][0].timestamp;
      tracking_data.forEach(tracks => {
        max_real_end = Math.max(max_real_end, tracks[tracks.length - 1] ?
          tracks[tracks.length - 1].timestamp : max_real_end)
      }
      )
      let final_end_date = Math.min(max_real_end, end)
      let last_index = (final_end_date - actual_start) / step
      last_index = Number.isInteger(last_index) ? last_index + 1 : last_index
      if (
        moment.unix(actual_start).isDST() !==
          moment.unix(final_end_date).isDST() &&
        step === 86400
      ) {
        return Math.round(last_index);
      } else {
        return last_index;
      }
    }
  }

  prepareTrackingData = (tracking_data, step, start, end, keys, selected_zones_in, selected_zones_out, set_state) => {

    if (tracking_data && tracking_data.length > 0) {

      let actual_start = this.getActualStart(start, tracking_data)

      let actual_end = this.getActualEnd(end, actual_start, step, tracking_data)
      this.props.setActualTrackbarPeriod(Number(actual_start),
        Number(actual_start)+Number(actual_end*step))
      let key_set = new Set(keys)
      let data = this.initializeData(key_set, actual_start, actual_end, step, tracking_data)
      data = this.transformData(data, tracking_data, actual_start,
        parseInt(actual_start) + Math.floor(actual_end * step), key_set, selected_zones_in, selected_zones_out, step, set_state)
      return data
    }
    return []
  }

  prepareNumbers = (total) => {
    const vals = Object.values(total)
    let max_ = 0, min_ = Number.MAX_SAFE_INTEGER, mean_ = 0, sum_ = 0, len = vals.length
    if (!vals || vals.length === 0)
      return [0, 0, 0, 0]
    vals.forEach((value, index) => {
      max_ = Math.max(value, max_)
      min_ = Math.min(value, min_)
      sum_ += value
      if (index === len - 1) {
        mean_ = Math.floor(sum_ / len)
      }
    })
    return [mean_, sum_, min_, max_]

  }
  compareProps(old_props, new_props) {

    if (old_props.tracks.length === new_props.tracks.length &&
      old_props.start === new_props.start &&
      old_props.end === new_props.end &&
      old_props.max_ticks === new_props.max_ticks &&
      old_props.graph_height === new_props.graph_height &&
      old_props.default_step === new_props.default_step &&
      old_props.tracks_loading === new_props.tracks_loading &&
      old_props.devices === new_props.devices
    )
      return false
    return true
  }

  componentWillReceiveProps(nextProps) {
    if (this.compareProps(this.props, nextProps)) {
      this.recomputeState(nextProps)
    }
  }

  recomputeState(props) {


    let keys = new Set()
    let zones_in = props.tracks.map(tracks => new Set())
    let zones_out = props.tracks.map(tracks => new Set())

    props.tracks.forEach((tracks, idx) =>
      tracks.forEach((element) => {
        keys.add(element.zone_in + '->' + element.zone_out)
        zones_in[idx].add(element.zone_in)
        zones_out[idx].add(element.zone_out)
      }))

    keys = Array.from(keys).sort()
    if (keys && keys.length) {
      let start_time = moment(props.start).format("X")
      let end_time = moment(props.end).format("X")

      let actual_start = this.getActualStart(start_time, props.tracks)
      let actual_end = this.getActualEnd(end_time, actual_start, this.state.step, props.tracks)
      let n_bars = ((parseInt(actual_start) + Math.floor(actual_end * this.state.step) - actual_start) / this.state.step)
      if (this.state.selected_keys && n_bars <= this.state.max_bars) {

        this.prepareTrackingData(props.tracks, this.state.step
          , moment(this.props.start).format("X"), moment(this.props.end).format("X")
          , keys
          , zones_in
          , zones_out
          , true
          , this.state.stepHours
          , this.state.stepDays
          , this.state.stepWeeks
          , this.state.stepYears
        )
        this.setState({
          start: props.start,
          end: props.end,
          tracks: props.tracks,
          keys: Array.from(keys),
          select_keys: keys.map(key => { return { "label": key, "value": key } }),
          selected_keys: keys.map(key => { return { "label": key, "value": key } }),
          zones_in: zones_in.map(zones_in_device => Array.from(zones_in_device).sort()),
          zones_out: zones_out.map(zones_in_device => Array.from(zones_in_device).sort()),
          selected_zones_in: zones_in,
          selected_zones_out: zones_out,
        })
      }

      else if (this.state.selected_keys && n_bars > this.state.max_bars) {
        this.props.createInfo("Please select a shorter period to view the result in hours.")

        this.setState({
          step: periods_to_seconds[this.props.tracking_data.step],
          stepHours: 0,
          stepDays: 1,
          selected_step_filters: new Set([this.props.tracking_data.step]),
          start: props.start,
          end: props.end,
          tracks: props.tracks,
          keys: Array.from(keys),
          select_keys: keys.map(key => { return { "label": key, "value": key } }),
          selected_keys: keys.map(key => { return { "label": key, "value": key } }),
          zones_in: zones_in.map(zones_in_device => Array.from(zones_in_device).sort()),
          zones_out: zones_out.map(zones_in_device => Array.from(zones_in_device).sort()),
          selected_zones_in: zones_in,
          selected_zones_out: zones_out,
        }, () => {
          this.prepareTrackingData(props.tracks, this.state.step
            , moment(props.start).format("X"), moment(props.end).format("X")
            , keys
            , zones_in
            , zones_out
            , true)
        });



      }
    }
  }


  handleZoneInSelect = (e,index) => {
    const value = e.target.value
    var values = this.state.selected_zones_in.map((zones) => new Set(zones))

    if (values[index].has(value)) {
      values[index].delete(value)
    } else {
      values[index].add(value)
    }

    this.setState({
      selected_zones_in: values
    })
    this.updatePlot(values)
  }

  handleZoneOutSelect = (e, index) => {
    const value = e.target.value
    var values = this.state.selected_zones_out.map((zones) => new Set(zones))

    if (values[index].has(value)) {
      values[index].delete(value)
    } else {
      values[index].add(value)
    }

    this.setState({
      selected_zones_out: values
    })

    this.updatePlot(null, values)
  }

  updatePlot = (selected_zones_in = null, selected_zones_out = null) => {
    let start_time = moment(this.state.start).format("X")
    let end_time = moment(this.state.end).format("X")

    let actual_start = this.getActualStart(start_time, this.state.tracks, this.state.stepHours
      , this.state.stepDays
      , this.state.stepWeeks
      , this.state.stepYears)

    let actual_end = this.getActualEnd(end_time, actual_start, this.state.step, this.state.tracks)

    if (this.state.selected_keys && (parseInt(actual_start) + Math.floor(actual_end * this.state.step) - actual_start) / this.state.step <= this.state.max_bars) {

      this.prepareTrackingData(this.state.tracks, this.state.step, start_time, end_time, this.state.selected_keys.map(obj => obj.value)
        , selected_zones_in ? selected_zones_in : this.state.selected_zones_in
        , selected_zones_out ? selected_zones_out : this.state.selected_zones_out
        , true
        , this.state.stepHours
        , this.state.stepDays
        , this.state.stepWeeks
        , this.state.stepYears)
    }
  }
  handleChangeSelectKeys = (selected) => {
    if(selected && selected.length>0){
    let new_keys = selected.map(o => o.value)
    this.setState({
      keys: new_keys,
      selected_keys: new_keys.map(key=>{return {"label":key,"value":key}}),
    },() => {
      this.updatePlot()
      });
    }
  }
  updateMetaData = (step_name) => {
    this.props.setStep(step_name.toLowerCase())
  }
  handleSelectStepFilter = (event) => {
    const step_name = event.target.value
    const step = periods_to_seconds[step_name]
    const old_step = this.state.step
    const stepHours = this.state.stepHours
    const stepDays = this.state.stepDays
    const stepWeeks = this.state.stepWeeks
    const stepYears = this.state.stepYears
    this.updateMetaData(step_name)
    this.setState({
      step: step,
      stepHours: step === periods_to_seconds["hours"] ? 1 : 0,
      stepDays: step === periods_to_seconds["days"] ? 1 : 0,
      stepWeeks: step === periods_to_seconds["weeks"] ? 1 : 0,
      stepYears: step === periods_to_seconds["years"] ? 1 : 0,
      selected_step_filters: new Set([step_name])
    }, () => {
      let start_time = moment(this.state.start).format("X")
      let end_time = moment(this.state.end).format("X")
      let actual_start = this.getActualStart(start_time, this.state.tracks)
      let actual_end = this.getActualEnd(end_time, actual_start, this.state.step, this.state.tracks)

      if(this.state.stepHours === 1 && (parseInt(actual_start) + Math.floor(actual_end * this.state.step) - actual_start) / this.state.step > this.state.max_bars)
      {
        this.setState({
          step: old_step,
          stepHours: stepHours,
          stepDays: stepDays,
          stepWeeks: stepWeeks,
          stepYears: stepYears,
          selected_step_filters: new Set([stepDays ? "Days" : stepWeeks ? "Weeks" : "Years"])
        })
        this.props.createInfo("Please select a shorter period to view the result in hours.")
      }
      this.updatePlot()
    });
  }

  render() {
    const { t } = this.props;
    const colourStyles = {
      control: styles => ({ ...styles, backgroundColor: 'rgb(61, 66, 70)', border: "none" }),
      menu: styles => ({ ...styles, backgroundColor: 'rgb(61, 66, 70)' }),
      multiValue: styles => ({ ...styles, color: 'white', backgroundColor: 'rgb(46, 49, 54)' }),
      multiValueLabel: styles => ({ ...styles, color: 'white', backgroundColor: 'rgb(46, 49, 54)' }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgb(46, 49, 54)' : 'rgb(61, 66, 70)',
        color: 'white',
      }),
    }
    return (

      <Container className="ml-0 pr-0">
        <LoadingOverlay
          active={this.props.tracks_loading}
          spinner={<SpinnerWintics />}
        >
          <Row className="row-stats">
            <span className="center-subtitle">
              <span >{t('min')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {numberWithSpaces(this.state.min_)}</span>
              <span >{t('average')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {numberWithSpaces(this.state.mean_)}</span>
              <span >{t('max')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {numberWithSpaces(this.state.max_)}</span>
              <span >{t('total')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {numberWithSpaces(this.state.sum_)}</span>
            </span>
            <span>
              <span className="pull-right">
                <Button className="btn btn-yellow btn-excel" onClick={(e) =>
                  exportToCSV(this.state.data
                    , this.state.total
                    , this.state.selected_keys.map(e => e.value)
                    , "data_" + this.props.devices.map(device => device.label).join("_") + "_"
                    + moment(this.state.start).format() + "_" + moment(this.state.end).format()) +
                  "_" + this.state.selected_step_filters.values().next().value}>
                  <FontAwesomeIcon icon={faFileExcel} /> Excel</Button>


              </span>
            </span>
          </Row>
          <Row className="trackBarDiv" >

            <TrackResponsiveBar data={this.state.data} total={this.state.total}
              keys={Array.from(this.state.keys)} index={index} max_ticks={this.props.max_ticks}
              max_value={this.state.max_} graph_height={this.props.graph_height}
              t={t}
            />

          </Row>
        </LoadingOverlay>
        <Row className="mt-2">
          <Select className="select-wintics"
            styles={colourStyles}
            value={this.state.selected_keys}
            onChange={this.handleChangeSelectKeys}
            options={this.state.select_keys}
            defaultValue={this.state.select_keys}
            closeMenuOnSelect={false}
            isMulti
          />
        </Row>

        <Row className="mb-1 mt-3">
          <Col md="12" className="pl-0 pr-0">
            <Form.Group as={Row} inline="true" controlId="Form.stepsize">
              {this.state.step_filters.map((z, index) => (
                <CheckboxWintics
                  handleSelectChange={this.handleSelectStepFilter}
                  item={z}
                  key={z + "_path"}
                  aftername={"-paths"}
                  selected_items={this.state.selected_step_filters} />
              ))}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className=" pr-0">
        {/* <Form>
        <Row>
          <span className="pl-0 pr-0 mr-4">
        {this.state.zones_in.map((zones,index)=>(
          <Form.Group key={"zones_in_element_" + index}
          as={Row} inline="true" controlId="Form.zonein">
            <Form.Label className="ml-0 text-smaller">
            <span className="mr-4">{this.props.devices &&
            this.props.devices.length>index?this.props.devices[index].label:""}: </span>
            {t('zoneIn')} </Form.Label>
            {zones.map((z, idx) => (
              <div key={"zones_in_"+z} className='checkboxCustom'>
              <input
                  type="checkbox"
                  value={z}
                  inline="true"
                  name={"input"+z+"-zones-category"+index}
                  label={z }
                  onChange={event => this.handleZoneInSelect(event,index)}
                  checked={this.state.selected_zones_in[index].has(z)}
                  id={"input"+z+"-zones-category"+index }
                  style={{
                      'height': 0,
                      'width': 0,
                      'position': 'absolute',
                  }}
              />
              <label htmlFor={"input"+z+"-zones-category"+index} className="text-smaller">

                  <FontAwesomeIcon
                      className={this.state.selected_zones_in[index].has(z) ?
                          'checked-category' : 'unchecked-category'}
                      icon={['fas', 'circle']}
                      style={{
                          'marginRight': '5px',
                      }}
                  />
                  {z} </label>
          </div>
            ))}
          </Form.Group>)
          )}
          </span>
          <span className="pl-0 pr-0">
          {this.state.zones_out.map((zones,index)=>(
          <Form.Group as={Row} inline="true" key={"f1"+index} controlId="Form.zoneout">
            <Form.Label className="ml-0 text-smaller"> {t('zoneOut')} </Form.Label>
            {this.state.zones_out[index].map((z, idx) => (
              <div key={"check_"+idx} className='checkboxCustom'>
              <input
                  type="checkbox"
                  value={z}
                  inline="true"
                  name={"output"+z+"-zones-category"+index}
                  label={z}
                  onChange={event => this.handleZoneOutSelect(event,index)}
                  checked={this.state.selected_zones_out[index].has(z)}
                  id={"output"+z+"-zones-category"+index}
                  style={{
                      'height': 0,
                      'width': 0,
                      'position': 'absolute',
                  }}
              />
              <label htmlFor={"output"+z+"-zones-category"+index} className="text-smaller">

                  <FontAwesomeIcon
                      className={this.state.selected_zones_out[index].has(z) ?
                          'checked-category' : 'unchecked-category'}
                      icon={['fas', 'circle']}
                      style={{
                        'marginRigh': '5px',
                      }}
                  />
                  {z} </label>
          </div>
            ))}
          </Form.Group>))}
          </span>
          </Row>
          </Form> */}
      </Col>
      </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  tracking_data: state.tracking_data,
});


const mapDispatchToProps = {
  getColors,
  createInfo,
  setStep,
  setActualTrackbarPeriod
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TrackBarPaths))
