/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/fontawesome';
import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0Context } from "@auth0/auth0-react";
import Wrapper from "./components/accounts/Wrapper";
import './i18n';
import moment from "moment";
/* import 'core-js/stable';*/
Promise.prototype.finally = Promise.prototype.finally || {
  finally (fn) {
    const onFinally = value => Promise.resolve(fn()).then(() => value);
    return this.then(
      result => onFinally(result),
      reason => onFinally(Promise.reject(reason))
    );
  }
}.finally;
moment.updateLocale('en', {
    week: {
      dow : 1, // Monday is the first day of the week.
    }
  });

ReactDOM.render(
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin+"/dashboard/callback"}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            scope="read:data"
            cacheLocation="memory"
        >
            <App />
        </Auth0Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
