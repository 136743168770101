
import {combineReducers} from 'redux';
import infos from './infos';
import messages from './messages';
import auth from './auth';
import {DESTROY_SESSION} from '../actions/types';
import errors from './errors';
import tracking_data from './tracking_data';
const appReducer = combineReducers({
  messages,
  infos,
  auth,
  errors,
  tracking_data,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) {
    sessionStorage.removeItem('token');
  }

  return appReducer(state, action);
};
export default rootReducer;
