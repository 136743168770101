import {
  SET_SHOW_MODAL_ACTIVATION,
  SET_SHOW_MODAL_REGISTERED,
  USER_LOADED,
  SET_TOKEN,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  SET_LAST_NAME,
  SET_FIRST_NAME,
  SET_COMPANY,
  REGISTER_FAIL,
  SET_COORDINATES,
  UPDATE_PROFILE,
  SET_USER_DEVICES
} from '../actions/types';

const initialState = {
  token: sessionStorage.getItem('token'),
  isAuthenticated: null,
  isRegistered: null,
  isLoading: false,
  user: null,
  showModalRegistered: false,
  showModalActivation: false,
  databases: [],
  role: null,
  first_name: '',
  last_name: '',
  company: '',
  language: '',
};


export default (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_SHOW_MODAL_ACTIVATION:
      return {
        ...state,
        showModalActivation: payload,
      };

    case SET_FIRST_NAME:
      return {
        ...state,
        first_name: payload,
      };
    case SET_LAST_NAME:
      return {
        ...state,
        last_name: payload,
      };
    case SET_COMPANY:
      return {
        ...state,
        company: payload,
      };
    case SET_SHOW_MODAL_REGISTERED:
      return {
        ...state,
        showModalRegistered: payload,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case SET_USER_DEVICES:
      return {
        ...state,
        databases: payload
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        databases: payload.databases,
        role: payload.role,
        user: payload.user,
      };
    case SET_TOKEN:
      sessionStorage.setItem('token', payload.token);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        isLoading: false,
        user: payload,
      };
    case LOGIN_SUCCESS:
      sessionStorage.setItem('token', payload.token);
      let language =  payload.language && payload.language !== ""
        ? payload.language : "fr"
      localStorage.setItem('i18nextLng', language);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        isLoading: false,
        access_token: payload.access_token,
        id_token: payload.id_token,
        databases: payload.databases,
        role: payload.role,
        user: payload.user,
        language: language,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        isRegistered: true,
        showModalRegistered: true,
        isLoading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      sessionStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        user: null,
      };
    case REGISTER_FAIL:
      sessionStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        user: null,
      };
    case UPDATE_PROFILE:
      localStorage.setItem('i18nextLng', payload.languageValue);
        return {
        ...state,
        first_name: payload.first_name,
        last_name: payload.last_name,
        company: payload.company,
        language: payload.languageValue,
      };
      /* falls through */
    case SET_COORDINATES:
      const coordinates = payload;
      return {
        ...state,
        databases: state.databases.map((database) => {
          const coord = coordinates.find((coord) =>
            String(coord.id) === String(database.id));

          if (coord && coord.lat && coord.lon) {
            const device = database;
            device['geometry'] = [coord.lat, coord.lon];
            return device;
          }
          return database;
        }),
      };
    default:
      return state;
  }
};
