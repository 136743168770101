/* eslint-disable react/prop-types */
import React, { Component, Fragment } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { hideMessage } from "../../actions/messages";
import { withTranslation }from "react-i18next"
export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired,
    infos: PropTypes.object.isRequired,
  };

  componentDidUpdate(newProps) {
    const { error, alert, message, info, t} = this.props;
    if (error.msg) {
      if (error.msg.filepath) alert.error(error.msg.filepath);
      if (error.msg.name) alert.error(`Name: ${error.msg.name.join()}`);
      if (error.msg.detail) alert.error(`${error.msg.detail}`);
      if (typeof error.msg === "string")
        alert.error(`${error.msg}`, {
          timeout: 8000,
          onClose: this.props.hideMessage,
        });
    }

    if (message.msg) {
      alert.success(message.msg, {
        timeout: 4000,
        onClose: this.props.hideMessage,
      });
    }
    if (this.props.infos.info) {
      alert.info(this.props.infos.info, {
        timeout: 4000,
      });
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
  infos: state.infos,
});

const mapDispatchToProps = {
  hideMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert()(Alerts));
