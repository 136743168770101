// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
    faArrowRight,
    faArrowCircleRight,
    faCircle,
    faMinusSquare,
    faPlus,
    faDownload
} from '@fortawesome/free-solid-svg-icons';

library.add(
    // more icons go here
    faArrowRight,
    faArrowCircleRight,
    faCircle,
    faMinusSquare,
    faPlus,
    faDownload
);