/* eslint-disable */

import React, {Component, Fragment} from 'react';
import {Provider} from 'react-redux';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css';
import './App.css';
import {
  Container,
} from 'react-bootstrap';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import {Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import Header from './components/layout/Header';
import Alerts from './components/layout/Alerts';

import Home from './components/Home';
import Callback from './components/Callback';
import Profile from './components/accounts/Profile';
import Loading from './components/common/Loading';
import { withAuthenticationRequired, useAuth0} from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import {DESTROY_SESSION} from './actions/types';
import {returnErrors} from './actions/messages';
import {APIFactory} from './components/common/API';

// Alert Options
const alertOptions = {
  timeout: 3000,
  position: 'top center',
  offset: '100px',

};
/**
* The main component for the app. It contains all of the sub components.
*/
export function App (props) {
  const { isAuthenticated, logout } = useAuth0();
  const {dispatch} = store

  const withAuth = (component) => {
    return  withAuthenticationRequired(component,{
      onRedirecting: function(){return <Loading />},
      loginOptions: {
        ui_locales: i18next.language && i18next.language != "" ?
          i18next.language : "fr"
      }
    })
  }
  APIFactory.interceptors.response.use(undefined, function (err) {
    if(err && err.response && err.response.data){
      let error_type = err.response.data.error_type
      if(error_type === 'indalid_header' || error_type === 'token_expired'){
        dispatch(returnErrors(i18next.t(error_type), 200));
        logout({returnTo: window.location.origin+'/dashboard/login'});
      }
    }
    return Promise.reject(err);
  });
  // eslint-disable-next-line require-jsdoc

  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Router basename="/dashboard">
          <Fragment>
            <Header />
            <Alerts />
            <Container fluid className="margin-top-body">
              <Switch>
                <Route exact path="/register" />
                <Route exact path="/callback" component={Callback} />
                <Route exact path="/Home" component={withAuth(Home)} />
                <Route exact path="/profile" component={withAuth(Profile)} />
                <Route exact path="/" component={withAuth(Home)} />
                <Route exact path="*" component={withAuth(Home)} />
              </Switch>
            </Container>
          </Fragment>
        </Router>
      </AlertProvider>
    </Provider>
  );
}

export default withTranslation()(App);
