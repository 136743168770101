/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { connect, useSelector } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCheck, faKey, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import {
  update_profile, update_user_profile, change_password_email,
  setFirstName, setLastName, setCompany
} from "../../actions/auth"
import {returnErrors}from "../../actions/messages"
import { Trans, withTranslation } from 'react-i18next'
import { Auth0Context, withAuth0 } from "@auth0/auth0-react";
import Select from "react-select";
import i18next from "i18next";
class Profile extends React.Component {
  constructor(props) {
    super(props)
    let { user, logout } = this.props.auth0
    let available_languages = [
      {value:'en', label:'English'},
      {value:'fr', label:'Français'}
    ]
    let user_metadata = user["https://vision.wintics.com/user_metadata"]
    let initialLanguage = this.props.auth.language === "" ? available_languages
      .find(el => el.value === user_metadata.language)
      : available_languages
      .find(el => el.value === this.props.auth.language)
    this.state = {
      first_name: this.props.auth.first_name === "" ? user_metadata.first_name
        : this.props.auth.first_name,
      last_name: this.props.auth.last_name === "" ? user_metadata.last_name
        : this.props.auth.last_name,
      company: this.props.auth.company === "" ? user_metadata.company
        : this.props.auth.company,
      language: initialLanguage,
      isEdit: false,
      languages: available_languages,
      initialLanguage: initialLanguage
    }
  }

  toggleIsEdit = () => {
    this.setState({ isEdit: !this.state.isEdit })
  }
  handleFirstNameEdit = e => {
    this.setState({ first_name: e.target.value })
  };
  handleLastNameEdit = e => {
    this.setState({ last_name: e.target.value })
  };
  handleCompanyEdit = e => {
    this.setState({ company: e.target.value })
  };
  handleLanguageEdit = selected => {
    this.setState({ language: selected })
  };


  updateProfileHandler = () => {
    if ((this.firstNameInput.value === "" || this.lastNameInput.value === ""
    || this.companyInput.value === "")) {
      this.props.returnErrors("Please fill in all of the fields.",400 )
    } else{
      // i18next.changeLanguage(this.state.language.value)
      this.toggleIsEdit();
      this.props.update_profile(this.state.first_name, this.state.last_name
        , this.state.company, this.state.language.value)
    }
   }
  render() {
    const langs = {"fr" : "Français", "en" : "English"}
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "rgb(61, 66, 70)",
        border: "none",
      }),
      menu: (styles) => ({ ...styles, backgroundColor: "rgb(61, 66, 70)" }),
      singleValue: (styles) => ({ ...styles, color: "white" }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: "white",
        backgroundColor: "rgb(46, 49, 54)",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? "rgb(46, 49, 54)"
          : "rgb(61, 66, 70)",
        color: "white",
      }),
      valueContainer: (base) => ({
        ...base,
      }),
    };
    const { t } = this.props
    return (
      <div className="col-md-6 m-auto text-white">
        <div className="card card-dark card-body ">
          <Row>
            <Col xs="3">
              <div className=" m-auto">
                <h6 className="text-center graph-icon"><FontAwesomeIcon icon={faUserCircle} /></h6>
              </div>
            </Col>
            <Col xs="9">
              <Row className="row-info">
                <Col xs="4">{t('firstName')}:</Col>
                <Col xs="8">
                  {
                    this.state.isEdit ?
                      <p>
                        <input id="first_name" className="dark-input"
                        ref={(firstNameInput) => { this.firstNameInput = firstNameInput }}
                        type="text" onChange={this.handleFirstNameEdit}
                        defaultValue={this.state.first_name}></input></p>
                      :
                      <p onClick={this.toggleIsEdit}>{this.state.first_name}</p>
                  }
                </Col>
              </Row>
              <Row className="row-info">
                <Col xs="4">{t('lastName')}:</Col>
                <Col xs="8">
                  {
                    this.state.isEdit ?
                      <p><input id="last_name" className="dark-input"
                      ref={(lastNameInput) => { this.lastNameInput = lastNameInput }}
                      type="text" onChange={this.handleLastNameEdit}
                      defaultValue={this.state.last_name}></input></p>
                      :
                      <p onClick={this.toggleIsEdit}>{this.state.last_name}</p>
                  }
                </Col>
              </Row>

              <Row className="row-info">
                <Col xs="4">{t('company')}:</Col>
                <Col xs="8">
                  {
                    this.state.isEdit ?
                      <p><input id="company" className="dark-input"
                      ref={(companyInput) => { this.companyInput = companyInput }}
                      type="text" onChange={this.handleCompanyEdit}
                      defaultValue={this.state.company}></input></p>
                      :
                      <p onClick={this.toggleIsEdit}>{this.state.company}</p>
                  }
                </Col>
              </Row>
              <Row className="row-info">
                <Col xs="4">{t('language')}:</Col>
                <Col xs="8">

                  {
                    this.state.isEdit ?
                      <Select
                      className="select-wintics"
                      value={this.state.language}
                      styles={colourStyles}
                      options={this.state.languages}
                      // defaultValue={this.state.language}
                      closeMenuOnSelect={true}
                      isDisabled={!this.state.isEdit}
                      onChange={this.handleLanguageEdit}
                      />
                      :
                      <p onClick={this.toggleIsEdit}>{this.state.language?
                         this.state.language["label"]:"Not Specified"}</p>
                  }
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            {
              this.state.isEdit ?
                <Button type="confirm" onClick={this.updateProfileHandler}
                className="m-auto btn btn-confirm" ><FontAwesomeIcon icon={faCheck} />
                 {t('confirm')}</Button>
                :
                <Button onClick={this.toggleIsEdit} className="m-auto btn btn-yellow" >
                  <FontAwesomeIcon icon={faPen} /> {t('modifyButton')}</Button>
            }
          </Row>

          <Row className="row-info mt-4">
            <Col>
            <Trans i18nKey="changePasswordMessage" >
            To change your password, please click on this
            <span className="change-password-link"
               onClick={this.props.change_password_email}>link</span>
              and you will an receive email with the steps to follow.
          </Trans>
            </Col>
          </Row>
        </div>
      </div>

    );
  }
};


const mapStateToProps = state => ({
  auth: state.auth
});


const mapDispatchToProps = {
  update_profile,
  change_password_email,
  update_user_profile,
  setFirstName,
  setLastName,
  setCompany,
  returnErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(withTranslation()(Profile)))
