export const getConfig = (state) => {
  return {
    headers: {
      "Content-Type" : "application/json",
      "Authorization" : "Bearer " + state.auth.token
    }
  };
}
export const getConfigByAccessToken = (access_token) => {
  return {
    headers: {
      "Content-Type" : "application/json",
      "Authorization" : "Bearer " + access_token
    }
  };
}