/* eslint-disable */


import React, { Component, Fragment } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCircle} from '@fortawesome/free-solid-svg-icons'

export const MyResponsivePie = ({ data,total,formatNumbers, get_color /* see data tab */ }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        cornerRadius={0}
        colors={get_color?get_color:{scheme:"paired"}}
        // colors={{scheme: "paired"}}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', '0.3' ] ] }}
        radialLabelsSkipAngle={1}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#f1f1f1"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={25}
        radialLabelsLinkHorizontalLength={30}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        enableSlicesLabels={false}
        slicesLabelsSkipAngle={100}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        // tooltipFormat={(d)=> {return Math.floor(d/total*100) +"%"}}
        // tooltipFormat={(d)=> {return <tspan>Hello</tspan>}}
        tooltip={({ id, value, color, data, indexValue })=> {return (
                            <strong><div>
                                {id} : {Math.round(value/total*1000)/10 +"%" }</div>
                            {/* <div>{formatNumbers(value)} / {formatNumbers(total)}</div> */}
                            </strong>)

                        }

                        }
        radialLabel={d => `${d.label}: ${Math.floor(d.value/total*100)+"%"}`}
        sliceLabel={d => `${d.label}: ${Math.floor(d.value/total*100)+"%"}`}

        theme={{
          labels: {
              text: {
                fill: "white",
                fontWeight:"bold"
              }
          },
          tooltip: {
              container: {
                  background: '#333',
              },
          }
              }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                translateY: 56,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: 'white',
                symbolSize: 18,
                symbolShape: 'circle',
                // effects: [
                //     {
                //         on: 'hover',
                //         style: {
                //             itemTextColor: '#000'
                //         }
                //     }
                // ]
            }
        ]}
    />
)