import React, {Fragment} from 'react';
import {Marker, useMap} from 'react-leaflet';
import {ActiveCameraLocationIcon,
  InactiveCameraLocationIcon} from './CameraLocationIcon';
import MarkerPopup from './MarkerPopup';
import PropTypes from 'prop-types';

const CameraMarkers = (props) => {
  const {cameras, selectedIds} = props;
  const map = useMap();
  const markers = cameras.map((camera, index) => (
    <Marker
      key={index}
      position={camera.geometry}
      zIndexOffset={selectedIds && selectedIds.includes(camera.id)?
        1000:0}
      icon={selectedIds && selectedIds.includes(camera.id)?
        ActiveCameraLocationIcon :
        InactiveCameraLocationIcon}
    >

      <MarkerPopup isdisabled={props.isdisabled} map={map}
        onClickShowDataPopup={props.onClickShowDataPopup} data={camera}/>
    </Marker>
  ));
  return <Fragment>{markers}</Fragment>;
};
export default CameraMarkers;
CameraMarkers.propTypes = {
  cameras: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  isdisabled: PropTypes.bool,
};
