/* eslint-disable */
import React, { Component, useState } from 'react';
import SpinnerWintics from './SpinnerWintics';

export default function Loading(props) {
    return (
        <div className="d-flex justify-content-center" style={{marginTop : "200px"}}>
      <SpinnerWintics
        loading={true}
        size={100}
        />
        </div>
      );
}