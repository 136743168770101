/* eslint-disable */
import React, { Component, useState } from 'react'
import DotLoader from 'react-spinners/DotLoader'

export default function SpinnerWintics(props) {
    return (
      <DotLoader
        color="var(--wintics_yellow)"
        loading={props.loading}
        size={props.size? props.size : 60}
        />
      );
}