
import {
  SET_TRACKING_DATA,
  SET_SNAPSHOTS,
  SET_SELECTED_DEVICES,
  SET_TRACKS_LOADING,
  SET_SNAP_LOADING,
  SET_COLORS,
  SET_STEP,
  SET_COLORS_LOADING,
  DESTROY_SESSION,
  SET_TRACKS_DOWNLOADING,
  KEEP_DEVICES,
  SET_AVAILABILITY,
  SET_AVAILABLE_PERIOD,
  SET_SELECTED_PERIOD,
  SET_ACTUAL_TRACK_PERIOD,
  SET_WEATHER_HISTORY,
  SET_UPDATED_DATA,
  CLEAR_SELECTED_WEATHER,
  SET_BAR_GRAPH_DIMENSIONS,
  CLEAR_DATA
} from '../actions/types';

const initialState = {
  tracking_data: {},
  tracks_loading: false,
  tracks_downloading: false,
  snap_loading: false,
  snapshot: null,
  colors_loading: false,
  colors: {},
  loaded_periods: {},
  snapshots: {},
  hourly_availability: [],
  daily_availability: [],
  weekly_availability: [],
  yearly_availability: [],
  selected_availability: [],
  step: 'days',
  available_period: [],
  selected_period: [],
  time_zones: {},
  coordinates: [],
  selected_devices: [],
  hourly_weather: [],
  daily_weather: [],
  weekly_weather: [],
  yearly_weather: [],
  selected_weather: [],
  graph_bars_width: 610,
  graph_bars_offset: 39.14,
  bar_ticks: null
};

const TrackingDataReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_TRACKING_DATA:
      const oldTrackingData = state.tracking_data;
      const newTrackingData = payload['tracking_data'];
      const newLoadedPeriods = payload['loaded_periods'];
      const oldLoadedPeriods = state.loaded_periods;
      const allDevicesSelected = payload['all_devices_selected'];
      const newTimeZones = payload['time_zones'];
      const devices = payload["selected_devices"]
      return {
        ...state,
        tracking_data: Object.fromEntries(allDevicesSelected.map((device) =>
                    newTrackingData[device.value]?
                    [device['value'], newTrackingData[device.value]]:
                    [device['value'], oldTrackingData[device.value]])),
        loaded_periods: Object.fromEntries(allDevicesSelected.map((device) =>
                    newLoadedPeriods[device.value]?[device['value'],
                      newLoadedPeriods[device.value]]:
                    [device['value'], oldLoadedPeriods[device.value]])),
        time_zones: Object.fromEntries(allDevicesSelected.map((device) =>
                    newTimeZones[device.value]?[device['value'],
                      newTimeZones[device.value]]:
                    [device['value'], state.time_zones[device.value]])),
        selected_devices: devices
      };
    case SET_UPDATED_DATA:
      return {
        ...state,
        tracking_data: payload
      }
    case SET_SNAPSHOTS:
      return {
        ...state,
        snapshots: payload,
        snap_loading: false,
      };
    case SET_SELECTED_DEVICES:
      return {
        ...state,
        selected_devices: payload,
      };
    case KEEP_DEVICES:
    const devicesValues = payload
        .map((device) => device.value)
      return {
        ...state,
        tracking_data: Object.fromEntries(devicesValues.map(((device) =>
          [device, state.tracking_data[device]]))),
        loaded_periods: Object.fromEntries(devicesValues.map(((device) =>
          [device, state.loaded_periods[device]]))),
        selected_devices: payload
      };
    case SET_TRACKS_LOADING:
      return {
        ...state,
        tracks_loading: payload,
      };
    case CLEAR_DATA:
      return {
        ...state,
        tracking_data: {},
        selected_availability: [],
        selected_weather: [],
        loaded_periods: {},
        // available_period: [],
        actual_track_period: []
        }
    case CLEAR_SELECTED_WEATHER:
      return {
        ...state
        // selected_weather: []
      }
    case SET_TRACKS_DOWNLOADING:
      return {
        ...state,
        tracks_downloading: payload,
      };
    case SET_SNAP_LOADING:
      return {
        ...state,
        snap_loading: payload,
      };
    case SET_AVAILABILITY:
      let selectedAvailability = [];
      const start = state.available_period[0];
      const end = state.available_period[1];
      if (state.step === 'hours') {
        selectedAvailability = payload['hourly_availability'];
      } else if (state.step === 'days') {
        selectedAvailability = payload['daily_availability'];
      } else if (state.step === 'weeks') {
        selectedAvailability = payload['weekly_availability'];
      } else if (state.step === 'years') {
        selectedAvailability = payload['yearly_availability'];
      }
      return {
        ...state,
        hourly_availability: payload['hourly_availability'],
        daily_availability: payload['daily_availability'],
        weekly_availability: payload['weekly_availability'],
        yearly_availability: payload['yearly_availability'],
        selected_availability: selectedAvailability
            .filter((obj) => Number(obj.x) >=start && Number(obj.x)<=end),
      };
    case SET_COLORS:
      if (JSON.stringify(payload )!== JSON.stringify(state.colors)) {
        return {
          ...state,
          colors_loading: false,
          colors: payload,
        };
      }
      break;
    case SET_AVAILABLE_PERIOD:
      if (payload && payload.length === 2) {
        let selectedAvailability = state.selected_availability;
        const step = state.step;

        if (step === 'hours') {
          selectedAvailability = state.hourly_availability;
        } else if (step === 'days') {
          selectedAvailability = state.daily_availability;
        } else if (step === 'weeks') {
          selectedAvailability = state.weekly_availability;
        } else if (step === 'years') {
          selectedAvailability = state.yearly_availability;
        }

        return {
          ...state,
          available_period: payload,
          selected_availability: selectedAvailability,
        };
      } else {
        return state;
      }
    case DESTROY_SESSION:
      return {
        ...initialState,
      };
    case SET_SELECTED_PERIOD:
      return {
        ...state,
        selected_period: payload,
      };

    case SET_BAR_GRAPH_DIMENSIONS:
      return {
        ...state,
        graph_bars_width : payload['width'] > 0? payload['width'] : 610,
        bar_ticks : payload['bar_ticks'],
        graph_bars_offset : payload['offset'] > 0? payload['offset'] : 39.14
      };
    case SET_ACTUAL_TRACK_PERIOD:
      return {
        ...state,
        actual_track_period: payload,
      };
    case SET_COLORS_LOADING:
      return {
        ...state,
        colors_loading: payload,
      };
    case SET_WEATHER_HISTORY:
      let selectedWeather;
      if (state.step === 'hours') {
        selectedWeather = payload['hourly_weather'];
      } else if (state.step === 'days') {
        selectedWeather = payload['daily_weather'];
      } else if (state.step === 'weeks') {
        selectedWeather = payload['weekly_weather'];
      } else if (state.step === 'years') {
        selectedWeather = payload['yearly_weather'];
      }

      return {
        ...state,
        selected_weather: selectedWeather,
        hourly_weather: payload['hourly_weather'],
        daily_weather: payload['daily_weather'],
        weekly_weather: payload['weekly_weather'],
        yearly_weather: payload['yearly_weather']
      }
    case SET_STEP:
      const availability = [];
      const weather = [];
      const step = payload;
      if (step === 'hours') {
        return {
          ...state,
          step: step,
          selected_availability: state.hourly_availability,
          selected_weather: state.hourly_weather,
        };
      } else if (step === 'days') {
        return {
          ...state,
          step: step,
          selected_availability: state.daily_availability,
          selected_weather: state.daily_weather,
        };
      } else if (step === 'weeks') {
        return {
          ...state,
          step: step,
          selected_availability: state.weekly_availability,
          selected_weather: state.weekly_weather,
        };
      } else if (step === 'years') {
        return {
          ...state,
          step: step,
          selected_availability: state.yearly_availability,
          selected_weather: state.yearly_weather,
        };
      }
      return {
        ...state,
        step: step,
        selected_availability: availability,
        selected_weather: weather,
      };

    default:
      return state;
  }
};
export default TrackingDataReducer;
