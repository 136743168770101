/* eslint-disable react/prop-types */
import React, { Component, Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import logo from "../../logo.svg";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import LogoutButton from "../common/LogoutButton";
import { Auth0Context, withAuth0 } from "@auth0/auth0-react";
import { LinkContainer } from "react-router-bootstrap";
import { withTranslation} from "react-i18next"
export class Header extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };
  static contextType = Auth0Context;

  render() {
    let { user } = this.props.auth0;
    const { isAuthenticated } = this.context;
    const { t } = this.props;
    const authLinks = (
      <Fragment>
        <Nav className="mr-auto"></Nav>

        <Nav>
          <Nav.Item className="nav-link user-name">
            <NavDropdown
              alignRight={true}
              title={
                user
                  ? `${
                      this.props.auth.first_name !== ""
                        ? this.props.auth.first_name
                        : user["https://vision.wintics.com/user_metadata"]
                            .first_name
                    }
            ${
              this.props.auth.last_name !== ""
                ? this.props.auth.last_name
                : user["https://vision.wintics.com/user_metadata"].last_name
            }`
                  : ""
              }
              id="basic-nav-dropdown"
            >
              <LinkContainer className="deco-none" to="home">
                <NavDropdown.Item>{t('home')}</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer className="deco-none" to="profile">
                <NavDropdown.Item>{t('profile')}</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Item
                href={
                  "mailto:support@wintics.com?subject=" +
                  encodeURIComponent(t('emailSubject')) +
                  "&body=" +
                  encodeURIComponent(t('emailBody'))
                }
              >
                {t('support')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <LogoutButton></LogoutButton>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
        </Nav>
      </Fragment>
    );
    const guestLinks = (
      <Nav className="ml-auto">
        <NavLink
          className="nav-link"
          exact
          activeClassName="nav-link-active"
          to="/login"
        >
          {t('login')}
        </NavLink>
      </Nav>
    );

    return (
      <Navbar bg="dark" expand="md" variant="dark">
        <Navbar.Brand as={Link} to="/home">
          <img href="home" className="logo" src={logo} alt={"Wintics"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {isAuthenticated ? authLinks : guestLinks}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withAuth0(withTranslation()(Header)));
