/* eslint-disable react/no-deprecated */
import React, { Component } from "react";
import { connect } from "react-redux";
import "../styles/supervisor.css";
import moment from "moment";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { Container, FormControl, Button, Col, Row } from "react-bootstrap";
import {
  getTrackingDataByPeriod,
  getSnapshot,
  getColors,
  setAvailablePeriod,
  keepDevices,
  setSelectedPeriod,
  getDownPeriodsByPeriod,
  getWeatherHistory,
  getDeviceCoordinates,
  setBarGraphDimensions
} from "../actions/tracking_data";
import DevicesMap from "./GraphComponents/MapComponents/DevicesMap";
import { setUserInfo } from "../actions/auth";
import TrackBar from "../components/GraphComponents/TrackBar";
import TrackBarPaths from "./GraphComponents/TrackBarPaths";
import PiechartByAverage from "./GraphComponents/PiechartByAverage";
import PiechartBySum from "./GraphComponents/PiechartBySum";
import CalendarHeatMap from "./GraphComponents/CalendarHeatMap";
import LineAvailability from "./GraphComponents/LineAvailability";
import WeatherHistory from "./GraphComponents/WeatherHistory";
import Select from "react-select";
import SpinnerWintics from "../components/common/SpinnerWintics";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle as farQuestionCircle}  from '@fortawesome/free-regular-svg-icons'
import { faFileCsv, faSquareFull } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PopupWintics } from "../components/common/PopupWintics";
import { END_DATE } from "react-dates/constants";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { Auth0Context, withAuth0 } from "@auth0/auth0-react";
import { getDateFormat } from '../utils/timezones'
import { withTranslation } from "react-i18next";
import i18next from 'i18next';
import { getConfigByAccessToken } from '../utils/headers_config'
import { APIFactory } from './common/API'


class Home extends Component {
  static contextType = Auth0Context;
  constructor(props) {
    super(props);


    let { user } = this.props.auth0;
    this.setAccessToken = this.setAccessToken.bind(this);

    let today = new Date();
    today.setHours(23, 59, 59);
    let week_earlier = new Date(today);
    week_earlier.setDate(today.getDate() - 5);
    week_earlier.setHours(0, 0, 0);
    let end = moment(today);
    let start = moment(week_earlier).isDST()
      ? moment(week_earlier).subtract(1, "h")
      : moment(week_earlier);

    this.state = {
      start: start,
      end: end,
      start_bars: start,
      end_bars: end,
      tracking_data: null,
      tracks: null,
      snapshots: null,
      selected_tab: "bars_by_category",
      focusedInput: null,
      secondary_selected_tab: "weather_history",
      selected_start: start,
      selected_end: end,
      selected_availability: [],
      graph_bars_width : null,
      graph_bars_offset : null
    };
    this.getTrackingData = this.getTrackingData.bind(this);
    this.setAccessToken(user).then((a) => {

      // this.props.getDeviceCoordinates();
      // console.log(this.state.selected_devices)
      this.getTrackingData(
        this.state.selected_devices,
        this.state.start,
        this.state.end
      );
    });
    this.applyCallback = this.applyCallback.bind(this);
  }

  setAvailability (availability, d1, d2, start, step, period = null, selected_start = null,
    selected_end = null) {
    // We first compute the relative indices of the first and second dates in the array
    let first_index = Math.floor((d1 - start) / step)
    let second_index = Math.floor((d2 - start) / step)
    let last_index = availability.length-1
    let period_step = step
    let first_step = step
    let second_step = step
    let weeks_processing = (period == "weeks" || period == "years") && start !== selected_start
    let truncated_first_step = (parseInt(start) + step - parseInt(selected_start) )
    let truncated_last_step = (parseInt(selected_end) - (parseInt(start) + step * last_index))
    if (weeks_processing){
      if (first_index === 0){
        first_step = truncated_first_step
      }
      if (second_index === last_index){
        second_step = truncated_last_step
      }
    }
    if (second_index === first_index) {
    // If both of the indices are the same, we have to compute the percentage of the down period
    // to be deducted from the same index. (d1-d2)/step*100
      if (weeks_processing && last_index === 0)
        period_step = parseInt(selected_end) - parseInt(selected_start)
      else if (weeks_processing && first_index === 0)
        period_step = truncated_first_step
      else  if (weeks_processing && second_index === last_index)
        period_step = truncated_last_step
      availability[first_index] = Math.max(0, availability[first_index] - (d2 - d1) / period_step * 100)
      return availability
    }
    else if (second_index === first_index + 1) {

      // If the indices are next to each other, a percentage has to be deducted from each of the
      // positions. (end_first_period - d1)/step*100 and (d2 - end_first_period)/step*100
      availability[first_index] = Math.max(0, availability[first_index] -
                              (second_index * step - (d1 - start))
                              / first_step * 100)
      availability[second_index] = Math.max(0, availability[second_index]-
                              (d2 - start - second_index * step) / second_step * 100)
      return availability
    }
    else {

      // If the indices are distant from each other, the last and first indices have to be updated
      // by deducting the percentage same as the second if, and the indices between have to be 0.
      let delta = second_index - first_index
      availability[first_index] = Math.max(0, availability[first_index] -
                                  ((first_index + 1) * first_step - (d1 - start)) / first_step * 100)
      availability[second_index] = Math.max(0, availability[second_index]
                                - (d2 - start - second_index * second_step) / second_step * 100)
      for (var v = first_index + 1; v < second_index; v++) {
        availability[v] = 0
        if (v === second_index - 1) {
          return availability
        }
      }
    }
  }
  updateDimensions() {
    setTimeout(
      () => {
    var total_width;
    var first_offset;
    const gs = document.getElementsByTagName('g')
    if (gs && gs.length>0 &&
      gs[1].children && gs[1].children.length>2
      ){
      total_width =  gs[1].children[gs[1].childElementCount-2]
        .getBoundingClientRect().left -
        gs[1].children[0].getBoundingClientRect().left

      first_offset = gs[1].children[0].getBoundingClientRect().left
      +
      gs[1].children[0].getBoundingClientRect().width * 0.5
       - gs[1].children[gs[1].childElementCount-1]
      .getBoundingClientRect().left

      if( this.props.tracking_data.graph_bars_offset !== first_offset
        && this.props.tracking_data.graph_bars_width !== total_width
        ){
        this.props.setBarGraphDimensions(total_width, first_offset);
      }
    }
    else if (gs && gs.length>0 &&
      gs[1].children && gs[1].children.length == 2
      && gs[0].children.length>2
    )
    {
      total_width = gs[0].children[3].getBoundingClientRect().width
      first_offset = gs[0].children[3].getBoundingClientRect().left -
      gs[1].children[gs[1].childElementCount-1]
      .getBoundingClientRect().left
      if( this.props.tracking_data.graph_bars_offset !== first_offset
        && this.props.tracking_data.graph_bars_width !== total_width
        ){
          this.props.setBarGraphDimensions(total_width, first_offset);
      }
    }
  }, 10)

  }

  componentDidMount(){
     this.updateDimensions();
  window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount(){
     window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  async setAccessToken(user) {
    const access_token = await this.props.auth0.getAccessTokenSilently();
    const id_token = await this.props.auth0.getIdTokenClaims();
    const devices_resp_analyses = await APIFactory.get(
      "/v1/analyses", getConfigByAccessToken(access_token)
    )

    var devices_resp = {"data": Array()}
    for (const [key, value] of Object.entries(devices_resp_analyses.data)) {
      devices_resp.data.push({
        "label": value["name"],
        "lat": value["address"]["lat"],
        "lon": value["address"]["lon"],
        "id": key,
        "device_id": value["device_id"]
      });
    }
    let select_devices_data = devices_resp.data.sort(
      (a,b) => {
        if (a['id'] > b['id'])
          return 1
        if (a['id'] < b['id'])
          return -1
        return 0
      }
    ).map((device) => {
      device['device_id'] = device['device_id']
      device['value'] = device['id']
      device['label'] = device['label'] ? device['label'] : device['id']
      return device
    });

    let selected_devices =
    select_devices_data && select_devices_data.length
      ? [select_devices_data[0]]
      : [];

    this.setState({
      select_devices: select_devices_data,
      selected_devices: selected_devices,
      shown_device_images: selected_devices.map((device) => device.label),
    })
  
    const user_metadata_attribute =
      user["https://vision.wintics.com/user_metadata"];
    const app_metadata_attribute =
      user["https://vision.wintics.com/app_metadata"];

    this.props.setUserInfo({
      user: user.email,
      role: app_metadata_attribute.role,
      token: access_token,
      id_token: id_token,
      databases: devices_resp.data,
      first_name:
        this.props.auth.first_name === ""
          ? user_metadata_attribute.first_name
          : this.props.auth.first_name,
      last_name:
        this.props.auth.last_name === ""
          ? user_metadata_attribute.last_name
          : this.props.auth.last_name,
      company:
        this.props.auth.company === ""
          ? user_metadata_attribute.company
          : this.props.auth.company,
      language:
        this.props.auth.language === ""
          ? user_metadata_attribute.language
          : this.props.auth.language
    });
  }

  applyCallback(startDate, endDate) {
    let rounded_end_date = new Date(endDate);
    rounded_end_date.setHours(23, 59, 59);
    this.setState({
      start: startDate,
      end: moment(rounded_end_date),
    });
  }

  componentWillReceiveProps(props) {
    let snapshots = props.tracking_data.snapshots;
    var snaps = {};
    const len = Object.keys(snapshots).length;

    if (snapshots && len > 0 && this.state.selected_devices) {
      Object.keys(snapshots).forEach((key, index) => {
        let snapshot = snapshots[key];
        var reader = new FileReader();
        reader.readAsDataURL(snapshot);
        reader.onloadend = (e) => {
          if (this.state.selected_devices[index]) {
            snaps[this.state.selected_devices[index].label] = reader.result;

            if (Object.keys(snaps).length === len) {
              this.setState({
                snapshots: snaps,
              });
            }
          }
        };
      });
    }
    if ((this.state.selected_tab === "bars_by_category" ||
      this.state.selected_tab === "bars_by_path") &&
      this.props.tracking_data.tracking_data &&
      // Object.keys(this.props.tracking_data.tracking_data).length > 0 &&
      this.props.tracking_data.actual_track_period &&
      this.props.tracking_data.actual_track_period.length === 2 &&
      this.props.tracking_data.graph_bars_width > 0 &&
      this.props.tracking_data.graph_bars_offset > 0 &&
      (!this.props.tracking_data.selected_weather ||
      this.props.tracking_data.selected_weather.length === 0)){
      this.selectSecondaryTab("line_availability")
    }
     else {
      this.selectSecondaryTab("weather_history")
    }
    this.set_selected_availability(this.props.tracking_data.step);
    this.set_selected_weather(this.props.tracking_data.step);

  }

  /**
  * Sets the availability bases on the selected step.
  *
  * @param {String} step
  */
  set_selected_availability = (step) => {
    if (step === "hours") {
      this.setState({
        selected_availability: this.props.tracking_data.hourly_availability,
      });
    } else if (step === "days") {
      this.setState({
        selected_availability: this.props.tracking_data.daily_availability,
      });
    } else if (step === "weeks") {
      this.setState({
        selected_availability: this.props.tracking_data.weekly_availability,
      });
    } else if (step === "years") {
      this.setState({
        selected_availability: this.props.tracking_data.yearly_availability,
      });
    }
  };

  /**
  * Sets the weather bases on the selected step.
  *
  * @param {String} step
  */
  set_selected_weather = (step) => {
    if (step === "hours") {
      this.setState({
        selected_weather: this.props.tracking_data.hourly_weather,
      });
    } else if (step === "days") {
      this.setState({
        selected_weather: this.props.tracking_data.daily_weather,
      });
    } else if (step === "weeks") {
      this.setState({
        selected_weather: this.props.tracking_data.weekly_weather,
      });
    } else if (step === "years") {
      this.setState({
        selected_weather: this.props.tracking_data.yearly_weather,
      });
    }
  };

  /**
  * Downloads the CSV of the tracking data between the start and end periods.
  */
  downloadCSV = () => {
    if (this.state.selected_devices) {
      const dateFormat = getDateFormat()
      let start_rounded = new Date(this.state.start);
      start_rounded.setHours(0, 0, 0);
      start_rounded = moment(start_rounded);

      let end_rounded = new Date(this.state.end);
      end_rounded.setHours(23, 59, 59);
      end_rounded = moment(end_rounded);

      this.props.getTrackingDataByPeriod(
        start_rounded.format(dateFormat),
        end_rounded.format(dateFormat),
        this.state.selected_devices,
        "csv"
      );
    }
  };

  /**
  * Loads tracking data on click on maps. Uses getTrackingData to fetch the data.
  *
  * @param {String} device_id - selected device
  */
  getTrackingDataFromMaps(device_id) {
    const new_selected = [
      this.state.select_devices.find(
        (device) => String(device.value) === String(device_id)
      ),
    ];
    this.setState({
      selected_devices: new_selected,
    });
    this.getTrackingData(new_selected, this.state.start, this.state.end);
  }

  /**
  * Loads tracking data, gets snaps and down periods.
  *
  * @param {Array} selected_devices
  * @param {Timestamp} start
  * @param {Timestamp} end
  * @param {Timestamp} refresh_data
  */
  getTrackingData = (selected_devices, start, end, refresh_data = false) => {
    if (selected_devices) {
      this.setState({
        shown_device_images: selected_devices.map((device) => device.label),
      });
      let devices_to_be_updated = [];
      const dateFormat = getDateFormat()

      // Set start to beginning of the day
      let start_rounded = new Date(start);
      start_rounded.setHours(0, 0, 0);
      start_rounded = moment(start_rounded);
      // Set end last minute of the day
      let end_rounded = new Date(end);
      end_rounded.setHours(23, 59, 59);
      end_rounded = moment(end_rounded);
      this.props.setAvailablePeriod(start_rounded, end_rounded);
      this.props.getDownPeriodsByPeriod(
        start_rounded,
        end_rounded,
        selected_devices
      );
      this.props.getWeatherHistory(
        start_rounded,
        end_rounded,
        selected_devices
      );
      selected_devices.forEach((device, index) => {
        let id = device.value;
        let period_device = this.props.tracking_data.loaded_periods[id];
        if (
          !period_device ||
          refresh_data ||
          start_rounded.isBefore(period_device["start"], "day") ||
          end_rounded.isAfter(period_device["end"], "day")
        ) {
          devices_to_be_updated.push(device);
        }
        if (index === selected_devices.length - 1) {
          this.props.getSnapshot(
            selected_devices,
            this.props.tracking_data.snapshots
          );
          if (devices_to_be_updated.length) {
            this.props.getTrackingDataByPeriod(
              start_rounded.format(dateFormat),
              end_rounded.format(dateFormat),
              devices_to_be_updated,
              "JSON_ARRAY",
              selected_devices,
              this.props.tracking_data.tracking_data,
              this.props.tracking_data.loaded_periods
            );
            this.setState({
              start_bars: start_rounded,
              end_bars: end_rounded,
              selected_start: this.state.start,
              selected_end: this.state.end,
            });
          } else {
            this.props.keepDevices(selected_devices);
          }
        }
      });
      if (!refresh_data) {
        this.setState({
          start_bars: this.state.start.set({ hour: 0, minute: 0, second: 0 }),
          end_bars: this.state.end.set({ hour: 23, minute: 59, second: 59 }),
          selected_start: this.state.start,
          selected_end: this.state.end,
        });
      }
    }
  };

  /**
  * set selcted_devices on Selelect Devices change
  *
  * @param {Array} selected - selected devices
  */
  handleChangeSelectDevices = (selected) => {
    this.setState({ selected_devices: [selected] });
  };

  /**
  * Sets selected_tab on click on tabs
  *
  * @param {String} selected_tab - selected tab
  */
  selectTab = (selected_tab) => {
    this.setState({ selected_tab: selected_tab });
  };

  /**
  * Sets secondary selected_tab on click on tabs
  *
  * @param {String} selected_tab - selected tab
  */
  selectSecondaryTab = (secondary_tab) => {
    this.setState({ secondary_selected_tab: secondary_tab });
  };

  /**
  * Verifies if the selected date is in the acceptable range.
  *
  * @param {Date} day - calendar day
  * @return {Bool}
  */
  isOutsideRange = (day) => {
    if (this.state.focusedInput === END_DATE) {
      return day.isAfter(moment(new Date()), "day");
    }
    return false; // or whatever the default outside range is here
  };

  render() {
     this.updateDimensions();
     const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "rgb(61, 66, 70)",
        border: "none"
      }),
      input: (styles) => ({
        ...styles,
        color: "white"
      }),
      menu: (styles) => ({ ...styles, backgroundColor: "rgb(61, 66, 70)" }),
      singleValue: (styles) => ({ ...styles, color: "white" }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: "white",
        backgroundColor: "rgb(46, 49, 54)",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? "rgb(46, 49, 54)"
          : "rgb(61, 66, 70)",
        color: "white",
      }),
      valueContainer: (base) => ({
        ...base,
      }),
    };
    const { t } = this.props;
    //working total width between centers of bars

    return (
      <div className="fixed-overflow">
        <Container fluid className="block header-browse-data">
          <Row className="">
            <Col xs="4">
              <Row>
                <span className="subtitle center-subtitle ml-0 mr-3">
                  {t('device')}
                </span>
                <span className="selectdevicecol">
                  <Select
                    className="select-wintics"
                    value={this.state.selected_devices}
                    styles={colourStyles}
                    onChange={this.handleChangeSelectDevices}
                    options={this.state.select_devices}
                    defaultValue={this.state.selected_devices}
                    closeMenuOnSelect={true}
                    isDisabled={
                      this.props.tracking_data.tracks_loading ||
                      this.props.tracking_data.tracks_downloading
                    }
                    // isMulti
                  />
                </span>
              </Row>
            </Col>
            <Col xs="8">
              <Row>
                <span className="subtitle center-subtitle ml-0 mr-3">
                  {t('dateRange')}
                </span>
                <span>
                  <DateRangePicker
                    displayFormat="DD/MM/YYYY"
                    isOutsideRange={(day) => this.isOutsideRange(day)}
                    showDefaultInputIcon={true}
                    noBorder={true}
                    showClearDates={true}
                    startDate={this.state.start} // momentPropTypes.momentObj or null,
                    startDateId="start_date" // PropTypes.string.isRequired,
                    endDate={this.state.end} // momentPropTypes.momentObj or null,
                    endDateId="end_date" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) =>
                      this.applyCallback(startDate, endDate)
                    } // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput}
                    hideKeyboardShortcutsPanel={true}
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput })
                    } // PropTypes.func.isRequired,
                    minimumNights={0}
                  />
                </span>
                <span>
                  {this.state.selected_keys &&
                  this.state.selected_devices.length > 1 ? (
                    <PopupWintics
                      onClickMainButton={() => {
                        this.getTrackingData(
                          this.state.selected_devices,
                          this.state.start,
                          this.state.end
                        );
                      }}
                      modalContent={
                        <div>
                          <Row className="ml-2 mr-2">
                            You have selected more than one database. Traffic
                            statistics of those databases will sum up in the
                            chart. Do you want to continue?
                          </Row>
                        </div>
                      }
                      modalTitle={"Retreieving data from multiple databases"}
                      modalButtonClasses="btn btn-yellow btn-retrieve-data"
                      modalButtonDisabled={
                        this.props.tracking_data.tracks_loading ||
                        this.props.tracking_data.tracks_downloading
                      }
                      modalFooter="true"
                      footerMainBtnClasses="btn btn-yellow"
                      footerMainBtnContentoter="Confirm"
                      modalButton={t('showData')}
                    ></PopupWintics>
                  ) : (
                    <Button
                      className="btn btn-yellow btn-retrieve-data"
                      disabled={
                        this.props.tracking_data.tracks_loading ||
                        this.props.tracking_data.tracks_downloading
                      }
                      onClick={() =>
                        this.getTrackingData(
                          this.state.selected_devices,
                          this.state.start,
                          this.state.end
                        )
                      }
                    >
                      {t('showData')}
                    </Button>
                  )}
                </span>
                <span>
                  <Button
                    className="btn btn-yellow btn-retrieve-data"
                    disabled={
                      this.props.tracking_data.tracks_loading ||
                      this.props.tracking_data.tracks_downloading
                    }
                    onClick={this.downloadCSV}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faFileCsv} /> {t('downloadData')}
                  </Button>
                </span>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid className="block block-fit-content main-area ">
          <Row>
            <Col xs={7} className="pl-0 pr-0">
              <Container className="pl-0">
              <Row>
                  <span
                    className={
                      this.state.selected_tab === "bars_by_category"
                        ? "main-area-tab main-area-tab-selected"
                        : "main-area-tab"
                    }
                    onClick={() => this.selectTab("bars_by_category")}
                  >
                    {t('trafficByCategory')}
                  </span>
                  <span
                    className={
                      this.state.selected_tab === "bars_by_path"
                        ? "main-area-tab main-area-tab-selected"
                        : "main-area-tab"
                    }
                    onClick={() => this.selectTab("bars_by_path")}
                  >
                    {t('trafficByPath')}
                  </span>
                  <span
                    className={
                      this.state.selected_tab === "calendar_heatmap"
                        ? "main-area-tab main-area-tab-selected"
                        : "main-area-tab"
                    }
                    onClick={() => this.selectTab("calendar_heatmap")}
                  >
                    {t('calendarHeatmap')}
                  </span>
                  <span
                    className={
                      this.state.selected_tab === "piechart"
                        ? "main-area-tab main-area-tab-selected"
                        : "main-area-tab"
                    }
                    onClick={() => this.selectTab("piechart")}
                  >
                    {t('piechart')}
                  </span>
                </Row>
                <Row style={{paddingLeft: "15px"}}>
                  {this.state.selected_tab === "bars_by_category"
                  || this.state.selected_tab === 'weather_history'
                  ? (
                    <TrackBar
                      tracks={Object.values(
                        this.props.tracking_data.tracking_data
                      )}
                      time_zones={Object.values(
                        this.props.tracking_data.time_zones
                      )}
                      start={this.state.start_bars.utcOffset(Object.values(
                        this.props.tracking_data.time_zones
                      )[0],true)}
                      end={this.state.end_bars.utcOffset(Object.values(
                        this.props.tracking_data.time_zones
                      )[0],true)}
                      // start={this.state.start_bars}
                      // end={this.state.end_bars}
                      max_ticks={28}
                      max_bars={504}
                      graph_height={320}
                      default_step={86400}
                      tracks_loading={this.props.tracking_data.tracks_loading}
                      devices={this.props.tracking_data.selected_devices}
                    />
                  ) : this.state.selected_tab === "bars_by_path" ? (
                    <TrackBarPaths
                      tracks={Object.values(
                        this.props.tracking_data.tracking_data
                      )}
                      time_zones={Object.values(
                        this.props.tracking_data.time_zones
                      )}
                      start={this.state.start_bars.utcOffset(Object.values(
                        this.props.tracking_data.time_zones
                      )[0],true)}
                      end={this.state.end_bars.utcOffset(Object.values(
                        this.props.tracking_data.time_zones
                      )[0],true)}
                      max_ticks={28}
                      graph_height={320}
                      default_step={86400}
                      tracks_loading={this.props.tracking_data.tracks_loading}
                      devices={this.props.tracking_data.selected_devices}
                    />
                  ) : this.state.selected_tab === "piechart" ? (
                    <PiechartBySum
                      tracks={Object.values(
                        this.props.tracking_data.tracking_data
                      )}
                      start={this.state.start_bars}
                      end={this.state.end_bars}
                      tracks_loading={this.props.tracking_data.tracks_loading}
                      devices={this.state.selected_devices}
                    />
                  ) : this.state.selected_tab === "piechart_by_average" ? (
                    <PiechartByAverage
                      tracks={Object.values(
                        this.props.tracking_data.tracking_data
                      )}
                      start={this.state.start_bars.utcOffset(Object.values(
                        this.props.tracking_data.time_zones
                      )[0],true)}
                      end={this.state.end_bars.utcOffset(Object.values(
                        this.props.tracking_data.time_zones
                      )[0],true)}
                      tracks_loading={this.props.tracking_data.tracks_loading}
                      devices={this.state.selected_devices}
                    />
                  ) : (
                    <CalendarHeatMap
                      tracks={Object.values(
                        this.props.tracking_data.tracking_data
                      )}
                      time_zones={Object.values(
                        this.props.tracking_data.time_zones
                      )}
                      start={this.state.start_bars.utcOffset(Object.values(
                        this.props.tracking_data.time_zones
                      )[0],true)}
                      end={this.state.end_bars.utcOffset(Object.values(
                        this.props.tracking_data.time_zones
                      )[0],true)}
                      tracks_loading={this.props.tracking_data.tracks_loading}
                      devices={this.state.selected_devices}
                    />
                  )}
                </Row>
              </Container>
            </Col>
            <Col xs={5} className="pl-0">
              <Row className="title title-yellow title-zones">{t('zoningOverview')}</Row>
              <LoadingOverlay
                active={this.props.tracking_data.snap_loading}
                spinner={<SpinnerWintics />}
              >
                <Row className="zones-row">
                  <span className="zone-in-square">
                    <FontAwesomeIcon icon={faSquareFull} />
                  </span>
                  <span className="zone-in-text">{t('zoneIn')}</span>
                  <span className="zone-out-square">
                    <FontAwesomeIcon icon={faSquareFull} />
                  </span>
                  <span className="zone-out-text">{t('zoneOut')}</span>
                </Row>
                <Container className="card-gray zone-image">
                  <Carousel showThumbs={false} showIndicators={false}>
                    {this.state.snapshots
                      ? Object.keys(this.state.snapshots).map(
                          (device_name, index) => (
                            <div key={"device_name_" + device_name}>
                              <img
                                name={"image_" + index}
                                id={"image_" + index}
                                src={`${this.state.snapshots[device_name]}`}
                                alt=""
                                height="300px"
                                width="100%"
                              />
                              {/* <p
                                name={"image_label_" + index}
                                id={"image_label_" + index}
                                className="legend"
                              >
                                {device_name}
                              </p> */}
                            </div>
                          )
                        )
                      : null}
                  </Carousel>
                </Container>
              </LoadingOverlay>
            </Col>
          </Row>

          <Row>
            <Col md={7} className="pl-0">
              { (this.state.selected_tab === "bars_by_category" ||
              this.state.selected_tab === "bars_by_path") &&
              this.props.tracking_data.tracking_data &&
              Object.keys(this.props.tracking_data.tracking_data).length > 0 &&
              this.props.tracking_data.actual_track_period &&
              this.props.tracking_data.actual_track_period.length === 2 &&
              this.props.tracking_data.graph_bars_width > 0 &&
              this.props.tracking_data.graph_bars_offset > 0
               ?
              <div>
            <Row>
              {
                this.props.tracking_data.selected_weather &&
                this.props.tracking_data.selected_weather.length > 0 ?
              <span className="ml-0 tooltip-no-style">
                <span className={this.state.secondary_selected_tab === "weather_history" ?
                  "secondary-area-tab secondary-area-tab-selected" : "secondary-area-tab"}
                  onClick = {() => this.selectSecondaryTab('weather_history')}
                  >
                  {t('weather')}
                  <span className="tooltip">
                    <FontAwesomeIcon icon={farQuestionCircle}/>
                    <span className="tooltiptext ">
                    {t('weatherDescription')}
                    </span>
                  </span>
                </span>
              </span>
              :null
              }
              {
              this.props.tracking_data.graph_bars_width > 0 &&
              this.props.tracking_data.graph_bars_offset > 0 &&
              this.props.tracking_data.selected_availability &&
              this.props.tracking_data.selected_availability.length > 0 ?
              <span className="ml-0">
                <span className={this.state.secondary_selected_tab === "line_availability" ?
                    "secondary-area-tab secondary-area-tab-selected" : "secondary-area-tab"}
                    onClick = {() => this.selectSecondaryTab('line_availability')}
                    >
                    {t('availability')}
                    <span className="tooltip">
                      <FontAwesomeIcon icon={farQuestionCircle}/>
                      <span className="tooltiptext ">
                      {t('availabilityDescription')}
                    </span>
                  </span>
                </span>
              </span>:null
              }
            </Row>
          <Col md={12} className="pr-0" style={{marginTop: "8px"}}>
            {this.state.secondary_selected_tab === 'weather_history' ?
            this.props.tracking_data.selected_weather &&
            this.props.tracking_data.selected_weather.length > 0 ? (
                <WeatherHistory
                  data={
                    this.props.tracking_data.selected_weather
                    .reduce((filtered, element) => {
                      if (
                        Number(element["datetime"]) >=
                        this.props.tracking_data.actual_track_period[0] &&
                        Number(element["datetime"]) <
                        this.props.tracking_data.actual_track_period[1]
                      )
                      {
                        var someNewValue = {
                          x: moment
                            .unix(element["datetime"])
                            .utcOffset(
                              // 60
                              // "+01:00"
                              // element["time_zone"]
                              this.props.tracking_data.selected_weather[
                                this.props.tracking_data.selected_weather.length - 1
                              ]['time_zone']/60
                            )
                            .format(
                              this.props.tracking_data.step === "hours" ?
                              "DD/MM HH:mm" :
                              this.props.tracking_data.step === "days" ||
                              this.props.tracking_data.step === "weeks" ?
                              "DD/MM" : "YYYY"
                            ),
                          y: element["temp"],
                          precipitations : element["precipitations"]
                        }
                        filtered.push(someNewValue);
                      }
                      return filtered;
                    }, [])
                  }
                  max_ticks={28}
                  max_bars={504}
                  graph_height={320}
                  default_step={86400}
                  tracks_loading={this.props.tracking_data.tracks_loading}
                  devices={this.state.selected_devices}
                  step={this.props.tracking_data.step}
                  timezone={
                    this.props.tracking_data.selected_weather[
                      this.props.tracking_data.selected_weather.length - 1
                    ]['time_zone']/60
                  }
                  total_width = {this.props.tracking_data.graph_bars_width}
                  first_offset = {this.props.tracking_data.graph_bars_offset}
                />
              ) : null
            :

              this.state.secondary_selected_tab === "line_availability" &&
              this.props.tracking_data.graph_bars_width > 0 &&
              this.props.tracking_data.graph_bars_offset > 0 &&
              this.props.tracking_data.selected_availability &&
              this.props.tracking_data.selected_availability.length > 0 ? (
                <LineAvailability
                  data={this.props.tracking_data.selected_availability
                                  .reduce((filtered, element) => {
                                    if ( Number(element.x) / 1000 >=
                                    this.props.tracking_data.actual_track_period[0] &&
                                  Number(element.x) / 1000 <=
                                    this.props.tracking_data.actual_track_period[1])
                                    {
                                      let someNewValue = {
                                        x: moment
                                          .unix(Number(element["x"]) / 1000)
                                          .utcOffset(
                                            Object.values(
                                              this.props.tracking_data.tracking_data
                                            )[0][
                                              Object.values(
                                                this.props.tracking_data.tracking_data
                                              )[0].length - 1
                                            ]["tz"]
                                          )
                                          .format(
                                            this.props.tracking_data.step === "hours" ?
                                            "DD/MM HH:mm" :
                                            this.props.tracking_data.step === "days" ||
                                            this.props.tracking_data.step === "weeks" ?
                                            "DD/MM" : "YYYY"
                                          ),
                                        y: element["y"],
                                      }
                                      filtered.push(someNewValue);
                                    }
                                  return filtered;
                                }, [])}
                  max_ticks={28}
                  max_bars={504}
                  graph_height={320}
                  default_step={86400}
                  tracks_loading={this.props.tracking_data.tracks_loading}
                  devices={this.state.selected_devices}
                  step={this.props.tracking_data.step}
                  timezone={
                    Object.values(this.props.tracking_data.tracking_data)[0][
                      Object.values(this.props.tracking_data.tracking_data)[0]
                        .length - 1
                    ]["tz"]
                  }
                  total_width = {this.props.tracking_data.graph_bars_width}
                  first_offset = {this.props.tracking_data.graph_bars_offset}
                  bar_ticks = {this.props.tracking_data.bar_ticks}
                />
              ) : null
            }
            </Col>
            </div> : null}
            </Col>
            <Col md={5} className="pl-0">
              <DevicesMap
                devices={this.props.auth.databases.filter(
                  (database) => database.geometry
                )}
                button_disabled={
                  this.props.tracking_data.tracks_loading ||
                  this.props.tracking_data.tracks_downloading
                }
                onClickShowDataPopup={this.getTrackingDataFromMaps.bind(this)}
                selectedIds={
                  this.state.selected_devices
                    ? this.state.selected_devices.map((device) => device.value)
                    : []
                }
              ></DevicesMap>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tracking_data: state.tracking_data,
  auth: state.auth,
});

const mapDispatchToProps = {
  getTrackingDataByPeriod,
  getSnapshot,
  keepDevices,
  getColors,
  setSelectedPeriod,
  setAvailablePeriod,
  getDownPeriodsByPeriod,
  getWeatherHistory,
  getDeviceCoordinates,
  setUserInfo,
  setBarGraphDimensions
};

export default connect(mapStateToProps, mapDispatchToProps)
  (withAuth0(withTranslation()(Home)));
