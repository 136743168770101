import { APIFactory } from '../components/common/API'
import { returnErrors, createMessage } from "./messages";
import { getConfig } from '../utils/headers_config'
import {
  SET_SHOW_MODAL_ACTIVATION,
  SET_SHOW_MODAL_REGISTERED,
  USER_LOADED,
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  DESTROY_SESSION,
  UPDATE_PROFILE,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_COMPANY,
  SET_USER_DEVICES,
  SET_COORDINATES
} from "./types";
import i18next from 'i18next';
import { updateTrackingDataCategories } from './tracking_data';

export const loadUser = () => (dispatch, getState) => {
  if (!getState().auth.token) {
    return
  }
  dispatch({
    type: USER_LOADING
  })
  APIFactory
    .get("/load_user", getConfig(getState()))
    .then(res => {
      var payload ={
        "token":getState().auth.token,
        "databases":res.data.databases,
        "role":res.data.role,
        "user":res.data.username,
      }

      dispatch({
        type: USER_LOADED,
        payload: payload
      });
    })
    .catch(err => {
      dispatch(returnErrors(i18next.t('authError')));
    });

}

export const update_user_profile = (first_name,last_name,company)=>(dispatch,getState) => {
  dispatch({
    type: UPDATE_PROFILE,
    payload:{
      "first_name": first_name,
      "last_name": last_name,
      "company":company
    }
  });
}
export const update_profile = (first_name, last_name, company, languageValue) => (dispatch, getState) => {
  // Headers
  const body = {
      "first_name": first_name,
      "last_name": last_name,
      "company":company,
      "language": languageValue
  }
  // Request Body
  APIFactory
    .patch("/update_profile",body, getConfig(getState()))
    .then(res => {
      dispatch({
        type: UPDATE_PROFILE,
        payload:{
          "first_name": first_name,
          "last_name": last_name,
          "company": company,
          "languageValue": languageValue
        }
      });

      if (i18next.language !== languageValue){
        i18next.changeLanguage(languageValue);
        dispatch(updateTrackingDataCategories());
      }
      dispatch(createMessage(i18next.t('profileUpdatedMessage')));

    })
    .catch(err => {
    });

};

export const change_password_email = () => (dispatch, getState) => {
  APIFactory
    .post("/change_password_by_email",{}, getConfig(getState()))
    .then(res => {
      dispatch(createMessage(i18next.t('changePasswordPromptMessage')));
    })
    .catch(err => {
    });

};

export const logout = () => (dispatch, getState) => {
      dispatch({
        type: LOGOUT_SUCCESS
      });
      dispatch({ type: DESTROY_SESSION });
};

export const setShowModalRegistered = (value) => (dispatch, getState) => {
  dispatch({
    type: SET_SHOW_MODAL_REGISTERED,
    payload: value });
};

export const setShowModalActivation = (value) => (dispatch, getState) => {
  dispatch({
    type: SET_SHOW_MODAL_ACTIVATION,
    payload: value });
};

export const setFirstName = (value) => (dispatch, getState) => {
  dispatch({
    type: SET_FIRST_NAME,
    payload: value });
};

export const setLastName = (value) => (dispatch, getState) => {
  dispatch({
    type: SET_LAST_NAME,
    payload: value });
};

export const setCompany = (value) => (dispatch, getState) => {
  dispatch({
    type: SET_COMPANY,
    payload: value });
};


export const setUserInfo = (payload) => (dispatch, getState) => {
  if (i18next.language !== payload.language){
    i18next.changeLanguage(payload.language);
  }

  dispatch({
    type: LOGIN_SUCCESS,
    payload: payload
  });

  dispatch({
    type: SET_COORDINATES,
    payload: payload.databases
  });

}
export const get_devices = () => (dispatch, getState) => {
  // Request Body
  APIFactory
    .get("/devices", getConfig(getState()))
    .then(res => {
      console.log(res)
      dispatch({
        type: SET_USER_DEVICES,
        payload: res.data
      });
    })
    .catch(err => {
    });
};
