import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from "react-bootstrap"
import { useTranslation } from 'react-i18next'
function LogoutButton() {
  const { t } = useTranslation()
  const {
    isAuthenticated,
    logout,
  } = useAuth0();
  return (
    isAuthenticated && <Button
    className=" btn btn-yellow btn-logout  text-bold" onClick={() => {
      logout({ returnTo: window.location.origin+"/dashboard/login" });
    }}>{t('logOut')}</Button>
  );
}

export default LogoutButton;