import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {useAuth0} from '@auth0/auth0-react';
import {DESTROY_SESSION} from './actions/types';
import i18next from 'i18next';
import {returnErrors} from './actions/messages';
import {APIFactory} from './components/common/API';

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;