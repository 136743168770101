/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from "react";
import { getColors } from "../../actions/tracking_data";
import moment from "moment";
import { Col, Row, Form, Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import SpinnerWintics from "../common/SpinnerWintics";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { ResponsiveCalendar } from "@nivo/calendar";
import { withTranslation } from "react-i18next";

const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
const MyResponsiveCalendar = ({ data /* see data tab */ }) => (
  <ResponsiveCalendar
    data={data}
    theme={{
      labels: {
        text: {
          fill: "white",
          fontWeight: "bold",
        },
      },
      tooltip: {
        container: {
          background: "#333",
        },
      },
    }}
    from={
      data && data.length
        ? data[0]["day"].split("-")[0] + "-01-01"
        : "2020-01-01"
    }
    to={
      data && data.length
        ? data[0]["day"].split("-")[0] + "-12-31"
        : "2020-01-01"
    }
    emptyColor="var(--fill_4)"
    colors={[
      "#fed16f",
      "#febf68",
      "#fdac61",
      "#fc995a",
      "#fa8554",
      "#f7704d",
      "#f45847",
      "#f03c41",
      "#ec003b",
    ]}
    minValue="auto"
    margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
    yearSpacing={45}
    monthBorderWidth={4}
    monthBorderColor="var(--fill_3)"
    monthLegendPosition="after"
    monthLegendOffset={22}
    dayBorderWidth={2}
    dayBorderColor="var(--fill_3)"
    tooltip={({ day, date, value, color, x, y, size }) => (
      <strong style={{ color }}>
        {moment(day).format("DD MMM YYYY")}: {numberWithSpaces(value)}
        <br />
      </strong>
    )}
    legends={[
      {
        anchor: "bottom-right",
        direction: "row",
        translateY: 36,
        itemCount: 4,
        itemWidth: 42,
        itemHeight: 36,
        itemsSpacing: 16,
        itemTextColor: "white",
        itemDirection: "right-to-left",
      },
    ]}
  />
);

class CalendarHeatMap extends Component {
  constructor(props) {
    super(props);

    let keys = new Set();
    let zones_in = props.tracks.map((tracks) => new Set());
    let zones_out = props.tracks.map((tracks) => new Set());
    props.tracks.forEach((tracks, idx) =>
      tracks.forEach((element) => {
        keys.add(element.category);
        zones_in[idx].add(element.zone_in);
        zones_out[idx].add(element.zone_out);
      })
    );

    keys = new Set(Array.from(keys).sort());

    var default_step = 86400;

    this.state = {
      tracks: props.tracks,
      data: [],
      keys: keys,

      select_keys: Array.from(keys).map((key) => {
        return { label: key, value: key };
      }),
      selected_keys: Array.from(keys).map((key) => {
        return { label: key, value: key };
      }),
      zones_in: zones_in.map((zones_in_device) =>
        Array.from(zones_in_device).sort()
      ),
      zones_out: zones_out.map((zones_in_device) =>
        Array.from(zones_in_device).sort()
      ),

      selected_zones_in: zones_in,
      selected_zones_out: zones_out,
      step: default_step,
      start: this.props.start,
      end: this.props.end,
      loaderActive: true,

      total: 0,
      min_: 0,
      max_: 0,
      mean_: 0,
      sum_: 0,
    };
  }
  componentDidMount() {
    let data = this.prepareTrackingData(
      this.state.tracks,
      this.state.step,
      moment(this.state.start).format("X"),
      moment(this.state.end).format("X"),
      this.state.keys,
      this.state.selected_zones_in,
      this.state.selected_zones_out,
      true
    );
  }

  initializeData = (key_set, start, end, step, tracking_data) => {
    let data = [];
    let format = "YYYY-MM-DD";

    let max = 0;
    let actual_start = start;
    if (tracking_data.length > 0) {
      for (var i = 0; i < end; i++) {
        data.push({});
        key_set.forEach((key) => {
          data[i][key] = 0;
        });
        data[i]["day"] = moment
          .unix(start)
          .add(i * step, "seconds")
          .format(format);
        data[i]["value"] = 0;
      }
    }
    return data;
  };

  transformData = (
    data,
    tracking_data,
    start,
    end,
    key_set,
    selected_zones_in,
    selected_zones_out,
    step,
    set_state
  ) => {
    tracking_data.forEach((tracks, index_tracks) => {
      let len = tracks.length;
      tracks.forEach((element, ind) => {
        // const timestamp = moment(element['timestamp']).format("X")
        const timestamp = element["timestamp"];
        const category = element["category"];
        let index;
        if (
          timestamp <= end &&
          timestamp >= start &&
          key_set.has(category) &&
          (!selected_zones_in ||
            selected_zones_in.length <= index_tracks ||
            (selected_zones_in[index_tracks] &&
              selected_zones_in[index_tracks].has(element["zone_in"]))) &&
          (!selected_zones_out[index_tracks] ||
            selected_zones_out.length <= index_tracks ||
            (selected_zones_out[index_tracks] &&
              selected_zones_out[index_tracks].has(element["zone_out"])))
        ) {
          index = Math.floor((timestamp - start) / step);
          data[index][category]
            ? (data[index][category] += 1)
            : (data[index][category] = 1);
          data[index]["value"] += 1;
        }
        // TODO move this zones_in
        if (ind == len - 1 && set_state == true) {
          const totals = data.map((d) => d["value"]);
          const [mean_, sum_, min_, max_] = this.prepareNumbers(totals);
          this.setState({
            total: totals,
            data: data,
            mean_: mean_,
            sum_: sum_,
            min_: min_,
            max_: max_,
            loaderActive: false,
          });
        }
      });
    });
    return data;
  };
  getActualStart(start1, tracking_data) {
    let min_real_start = this.state.start;
    tracking_data.forEach((tracks) => {
      min_real_start = Math.min(
        min_real_start,
        tracks[0] ? tracks[0].timestamp : min_real_start
      );
    });
    let max_start = Math.max(start1, min_real_start);
    let actual_start;

    let timezone = "+01:00"
    if (tracking_data && tracking_data.length && tracking_data[0].length){
      timezone = tracking_data[0][0].tz
    }
    actual_start = moment.unix(max_start).utcOffset(timezone).startOf("day").format("X");
    return actual_start;
  }
  getActualEnd(end, actual_start, step, tracking_data) {
    let max_real_end = this.state.end;
    tracking_data.forEach((tracks) => {
      max_real_end = Math.max(
        max_real_end,
        tracks[tracks.length - 1]
          ? tracks[tracks.length - 1].timestamp
          : max_real_end
      );
    });
    let min_ = Math.min(max_real_end, end);
    if (min_ - actual_start > 86400) min_ -= 86400;
    const actual_end = (min_ - actual_start) / step;
    return actual_end;
  }
  prepareTrackingData = (
    tracking_data,
    step,
    start,
    end,
    keys,
    selected_zones_in,
    selected_zones_out,
    set_state
  ) => {
    if (tracking_data && tracking_data.length > 0) {
      let actual_start = this.getActualStart(start, tracking_data);

      let actual_end = this.getActualEnd(
        end,
        actual_start,
        step,
        tracking_data
      );
      let key_set = new Set(keys);
      let data = this.initializeData(
        key_set,
        actual_start,
        actual_end,
        step,
        tracking_data
      );
      // Initialize empty array for the data
      // reduce the array based on timestamp and zones
      data = this.transformData(
        data,
        tracking_data,
        actual_start,
        parseInt(actual_start) + Math.floor(actual_end * step),
        key_set,
        selected_zones_in,
        selected_zones_out,
        step,
        set_state
      );

      return data;
    }
    return [];
  };

  prepareNumbers = (total) => {
    const vals = Object.values(total);
    let max_ = 0,
      min_ = Number.MAX_SAFE_INTEGER,
      mean_ = 0,
      sum_ = 0,
      len = vals.length;
    if (!vals || vals.length == 0) return [0, 0, 0, 0];
    vals.forEach((value, index) => {
      max_ = Math.max(value, max_);
      min_ = Math.min(value, min_);
      sum_ += value;
      if (index == len - 1) {
        mean_ = Math.floor(sum_ / len);
      }
    });
    return [mean_, sum_, min_, max_];
  };
  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.recomputeState(nextProps);
    }
  }

  recomputeState(props) {
    let keys = new Set();
    let zones_in = props.tracks.map((tracks) => new Set());
    let zones_out = props.tracks.map((tracks) => new Set());

    props.tracks.forEach((tracks, idx) =>
      tracks.forEach((element) => {
        keys.add(element.category);
        zones_in[idx].add(element.zone_in);
        zones_out[idx].add(element.zone_out);
      })
    );

    keys = Array.from(keys).sort();
    if (keys && keys.length && JSON.stringify(this.state.keys) !== keys) {
      this.prepareTrackingData(
        props.tracks,
        this.state.step,
        moment(this.props.start).format("X"),
        moment(this.props.end).format("X"),
        keys,
        zones_in,
        zones_out,
        true
      );

      this.setState({
        start: props.start,
        end: props.end,
        tracks: props.tracks,
        keys: Array.from(keys),
        select_keys: keys.map((key) => {
          return { label: key, value: key };
        }),
        selected_keys: keys.map((key) => {
          return { label: key, value: key };
        }),
        zones_in: zones_in.map((zones_in_device) =>
          Array.from(zones_in_device).sort()
        ),
        zones_out: zones_out.map((zones_in_device) =>
          Array.from(zones_in_device).sort()
        ),
        selected_zones_in: zones_in,
        selected_zones_out: zones_out,
      });
    }
  }

  handleZoneInSelect = (e, index) => {
    const value = e.target.value;
    var values = this.state.selected_zones_in.map((zones) => new Set(zones));

    if (values[index].has(value)) {
      values[index].delete(value);
    } else {
      values[index].add(value);
    }

    this.setState({
      selected_zones_in: values,
    });
    this.updatePlot(values);
  };

  handleZoneOutSelect = (e, index) => {
    const value = e.target.value;
    var values = this.state.selected_zones_out.map((zones) => new Set(zones));
    if (values[index].has(value)) {
      values[index].delete(value);
    } else {
      values[index].add(value);
    }
    this.setState({
      selected_zones_out: values,
    });
    this.updatePlot(null, values);
  };

  updatePlot = (selected_zones_in = null, selected_zones_out = null) => {
    let start_time = moment(this.state.start).format("X");
    let end_time = moment(this.state.end).format("X");
    let actual_start = this.getActualStart(start_time, this.state.tracks);
    let actual_end = this.getActualEnd(
      end_time,
      actual_start,
      this.state.step,
      this.state.tracks
    );

    if (this.state.selected_keys) {
      this.prepareTrackingData(
        this.state.tracks,
        this.state.step,
        start_time,
        end_time,
        this.state.selected_keys.map((obj) => obj.value),
        selected_zones_in ? selected_zones_in : this.state.selected_zones_in,
        selected_zones_out ? selected_zones_out : this.state.selected_zones_out,
        true
      );
    }
  };
  handleChangeSelectKeys = (selected) => {
    if (selected && selected.length > 0) {
      let new_keys = selected.map((o) => o.value);
      this.setState(
        {
          keys: new_keys,
          selected_keys: new_keys.map((key) => {
            return { label: key, value: key };
          }),
        },
        () => {
          this.updatePlot();
        }
      );
    }
  };

  render() {
    const { t } = this.props;
    const selected_keys = this.state.selected_keys;
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "rgb(61, 66, 70)",
        border: "none",
      }),
      menu: (styles) => ({ ...styles, backgroundColor: "rgb(61, 66, 70)" }),
      multiValue: (styles) => ({
        ...styles,
        color: "white",
        backgroundColor: "rgb(46, 49, 54)",
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: "white",
        backgroundColor: "rgb(46, 49, 54)",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? "rgb(46, 49, 54)"
          : "rgb(61, 66, 70)",
        color: "white",
      }),
    };
    return (
      <Container className="ml-0 pr-0">
        <LoadingOverlay
          active={this.props.tracks_loading}
          spinner={<SpinnerWintics />}
        >
          <Row className="row-stats">
            <span className="center-subtitle">
              <span>Min:</span>
              <span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {numberWithSpaces(this.state.min_)}
              </span>
              <span>Average:</span>
              <span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {numberWithSpaces(this.state.mean_)}
              </span>
              <span>Max:</span>
              <span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {numberWithSpaces(this.state.max_)}
              </span>
              <span>Total:</span>
              <span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {numberWithSpaces(this.state.sum_)}
              </span>
            </span>
            <span></span>
          </Row>
          <Row className="trackBarDiv">
            <MyResponsiveCalendar data={this.state.data} />
            {/* <RechartsBarPlot resultSet={this.state.data} keys={this.state.keys} colors={colors} numberFormatter={numberFormatter}
           dateFormatter={dateFormatter} /> */}
          </Row>
        </LoadingOverlay>

        <Row className="mt-2">
          <Select
            className="select-wintics"
            styles={colourStyles}
            onChange={this.handleChangeSelectKeys}
            options={this.state.select_keys}
            defaultValue={this.state.select_keys}
            value={this.state.selected_keys}
            closeMenuOnSelect={false}
            isMulti
          />
        </Row>
        <Row>
          <Col xs="12" className=" pr-0">
            <Form>
              <Row className="mt-3">
                <span className="pl-0 pr-0 mr-4">
                  {this.state.zones_in.map((zones, index) => (
                    <>
                      <Form.Group
                        as={Row}
                        inline="true"
                        controlId="Form.zonein"
                      >
                        <Form.Label className="ml-0 text-smaller">
                          {t('zoneIn')}{" "}
                        </Form.Label>
                        {this.state.zones_in[index].map((z, idx) => (
                          <div key={"z_in" + idx} className="checkboxCustom">
                            <input
                              type="checkbox"
                              value={z}
                              inline="true"
                              name={"input" + z + "-zones-category" + index}
                              label={z}
                              onChange={(event) =>
                                this.handleZoneInSelect(event, index)
                              }
                              checked={this.state.selected_zones_in[index].has(
                                z
                              )}
                              id={"input" + z + "-zones-category" + index}
                              style={{
                                height: 0,
                                width: 0,
                                position: "absolute",
                              }}
                            />
                            <label
                              htmlFor={"input" + z + "-zones-category" + index}
                              className="text-smaller"
                            >
                              <FontAwesomeIcon
                                className={
                                  this.state.selected_zones_in[index].has(z)
                                    ? "checked-category"
                                    : "unchecked-category"
                                }
                                icon={["fas", "circle"]}
                                style={{
                                  marginRight: "5px",
                                }}
                              />
                              {z}{" "}
                            </label>
                          </div>
                        ))}
                      </Form.Group>
                    </>
                  ))}
                </span>
                <span className="pl-0 pr-0">
                  {this.state.zones_out.map((zones, index) => (
                    <Form.Group
                      key={"zout" + index}
                      as={Row}
                      inline="true"
                      controlId="Form.zoneout"
                    >
                      <Form.Label className="ml-0 text-smaller">
                        {" "}
                        {t('zoneOut')}{" "}
                      </Form.Label>

                      {this.state.zones_out[index].map((z, idx) => (
                        <div key={"szout" + idx} className="checkboxCustom">
                          <input
                            type="checkbox"
                            value={z}
                            inline="true"
                            name={"output" + z + "-zones-category" + index}
                            label={z}
                            onChange={(event) =>
                              this.handleZoneOutSelect(event, index)
                            }
                            checked={this.state.selected_zones_out[index].has(
                              z
                            )}
                            id={"output" + z + "-zones-category" + index}
                            style={{
                              height: 0,
                              width: 0,
                              position: "absolute",
                            }}
                          />
                          <label
                            htmlFor={"output" + z + "-zones-category" + index}
                            className="text-smaller"
                          >
                            <FontAwesomeIcon
                              className={
                                this.state.selected_zones_out[index].has(z)
                                  ? "checked-category"
                                  : "unchecked-category"
                              }
                              icon={["fas", "circle"]}
                              style={{
                                marginRigh: "5px",
                              }}
                            />
                            {z}{" "}
                          </label>
                        </div>
                      ))}
                    </Form.Group>
                  ))}
                </span>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  tracking_data: state.tracking_data,
});

const mapDispatchToProps = {
  getColors,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalendarHeatMap));
