export const SET_SHOW_MODAL_REGISTERED = 'SET_SHOW_MODAL_REGISTERED'
export const SET_SHOW_MODAL_ACTIVATION = 'SET_SHOW_MODAL_ACTIVATION'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const DESTROY_SESSION = 'DESTROY_SESSION'
export const SET_TOKEN = 'SET_TOKEN'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SET_FIRST_NAME = 'SET_FIRST_NAME'
export const SET_LAST_NAME = 'SET_LAST_NAME'
export const SET_COMPANY = 'SET_COMAPNY'
export const SET_USER_DEVICES = 'SET_USER_DEVICES'

export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'

export const SET_SELECTED_DEVICES = 'SET_SELECTED_DEVICES'
export const SET_SNAP_LOADING = 'SET_SNAP_LOADING'
export const SET_TRACKING_DATA = 'SET_TRACKING_DATA'
export const SET_TRACKS_LOADING = 'SET_TRACKS_LOADING'
export const CLEAR_DATA = 'CLEAR_DATA'
export const SET_TRACKS_DOWNLOADING = 'SET_TRACKS_DOWNLOADING'
export const CLEAR_SELECTED_WEATHER = 'CLEAR_SELECTED_WEATHER'
export const SET_SNAPSHOTS = 'SET_SNAPSHOTS'
export const SET_COLORS = 'SET_COLORS'
export const SET_COLORS_LOADING = 'SET_COLORS_LOADING'
export const KEEP_DEVICES = 'KEEP_DEVICES'
export const SET_STEP = 'SET_STEP'
export const SET_AVAILABILITY = 'SET_AVAILABILITY'
export const SET_AVAILABLE_PERIOD = 'SET_AVAILABLE_PERIOD'
export const SET_SELECTED_PERIOD = 'SET_SELECTED_PERIOD'
export const SET_ACTUAL_TRACK_PERIOD = 'SET_ACTUAL_TRACK_PERIOD'
export const SET_UPDATED_DATA = 'SET_UPDATED_DATA'
export const SET_COORDINATES = 'SET_COORDINATES'
export const SET_WEATHER_HISTORY = 'SET_WEATHER_HISTORY'
export const SET_BAR_GRAPH_DIMENSIONS = 'SET_BAR_GRAPH_DIMENSIONS'


export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const CREATE_INFO = 'CREATE_INFO'
export const GET_ERRORS = 'GET_ERRORS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const CLEAR_LEADS = 'CLEAR_LEADS'


export const SELECT_VIDEO = 'SELECT_VIDEO'

export const REDIRECT_TO_DASHBOARD = 'REDIRECT_TO_DASHBOARD'

export const SHOW_ALERT_MESSAGE= "SHOW_ALERT_MESSAGE"
export const HIDE_MESSAGE=  "HIDE_MESSAGE"

