/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react'

import {
    Container,
    Col,
    Row,
    Button,
    Table,
    Accordion,
    Card,
    Modal,
    ProgressBar
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function PopupWintics(props) {
    const [show, setShow] = useState(false);
    const popupRef = React.useRef();

    const handleClose = () => {setShow(false);if(props.onClickClose) props.onClickClose()};
    const handleShow = () => setShow(true);

    return (
        <>
            {props.showOpenModalButton===false?null:
            <Button className={props.modalButtonClasses?props.modalButtonClasses:"btn btn-yellow"}
            disabled = {props.modalButtonDisabled}
            onClick={(e)=>{handleShow()}}>
                {props.modalButton}
            </Button>
            }
            <Modal className="modal-wintics" show={props.show||show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.modalContent}</Modal.Body>
                {props.modalFooter?
                <Modal.Footer>
                <>
                        {props.showButtonCancel===false?null:
                            <Button className="btn btn-basic mr-2"  onClick={handleClose}>Cancel</Button>
                        }
                        <Button className={props.footerMainBtnClasses?props.footerMainBtnClasses:"btn btn-success"}
                              onClick={()=>{handleClose();props.onClickMainButton()}}>{props.footerMainBtnContent?
                              props.footerMainBtnContent:"Confirm"}</Button>
                            </>
                    {/* {props.modalFooterContent} */}
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button> */}
                </Modal.Footer>
                :
                <></>}
            </Modal>
        </>
    );
}