import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Loading from './common/Loading';
import store from '../store'
/**
* The page returned after Auth0 first register
* @return {JSX}
*/
function Callback({location}) {
  const {t} = useTranslation();
  const {dispatch} = store
  const {
    logout,
    isAuthenticated,
    isLoading,
    error
  } = useAuth0();
  const logmeout = () => {
    return logout({returnTo: window.location.origin+'/dashboard/login'});
  };

  const query = new URLSearchParams(location.search);
  const errorType = query.get('error');

  document.cookie.split(';').forEach(function(c) {
    document.cookie = c.replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
  if(!isAuthenticated && !isLoading){
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(logmeout, 15000);

    return <div className="col-md-5 m-auto text-white">
        <div className="card card-dark card-body ">
          <h5 className="text-center text-bold mb-4">
            {errorType=='account_created'?'Account Created':''}
          </h5>
          <h6 className="text-center mb-4 graph-icon">
            <FontAwesomeIcon icon={faLock} />
          </h6>
          <div className="text-center mb-4 ">
            {error ? t('callbackMessageBrowser') : t('callbackMessage')}
            </div>
          <div className="text-center mb-4 ">
            <Link className="a_yellow" onClick={()=>logmeout()}>
              {t('callbackLink')}
            </Link>
          </div>
        </div>
      </div>;
    }
    else if (isAuthenticated && !isLoading){
      return <Redirect to="/home" />
    }
    else
      return <Loading></Loading>
}
export default Callback;
Callback.propTypes = {location: PropTypes.object.isRequired};
