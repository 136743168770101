import React from 'react';
import {Popup} from 'react-leaflet';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const MarkerPopup = (props) => {
  const {t} = useTranslation();
  const {label, id} = props.data;
  return (
    <Popup>
      <Row className='poup-text'>{label}</Row>
      <Row className="mt-2 ">
        <Col className="text-center" >
          <Button className="btn btn-yellow "
            disabled={props.isdisabled}
            onClick={() => {
              props.map.closePopup();
              props.onClickShowDataPopup(id);
            }}
          > {t('showData')}</Button>
        </Col>
      </Row>
    </Popup>
  );
};
MarkerPopup.propTypes = {
  data: PropTypes.object,
  isdisabled: PropTypes.bool,
  map: PropTypes.object
};
export default MarkerPopup;
