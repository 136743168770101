import { APIFactory, tokenConfig } from '../components/common/API'
import { returnErrors, createMessage } from "./messages";
import {
  SET_TRACKING_DATA, SET_TRACKS_DOWNLOADING,
  SET_COLORS, SET_COLORS_LOADING, KEEP_DEVICES, SET_STEP,
  CREATE_MESSAGE, SET_TRACKS_LOADING, SET_SNAP_LOADING, SET_SNAPSHOTS,
  SET_AVAILABILITY, SET_AVAILABLE_PERIOD, SET_SELECTED_PERIOD,
  SET_ACTUAL_TRACK_PERIOD, SET_COORDINATES, SET_SELECTED_DEVICES,
  SET_WEATHER_HISTORY, SET_UPDATED_DATA, SET_BAR_GRAPH_DIMENSIONS,
  CLEAR_DATA, CLEAR_SELECTED_WEATHER
} from "./types";
import * as FileSaver from 'file-saver';
import moment from "moment";
import { getConfig } from '../utils/headers_config'
import i18next from 'i18next';

const h = 3600000
const d = h * 24
const w = d * 7

const agregate_weather = (weather, step, lower_hour, upper_hour, start_date) => {
  let agregated_weather = {}
  let i = 0
  while (i < weather.length) {
    let weather_entry = weather[i]
    let moment_datetime = moment.unix(weather_entry["datetime"])
      .utcOffset(Number(weather_entry["timezone"]))
    let hour = moment_datetime.local().hours()
    if (hour >= lower_hour && hour <= upper_hour){
      if (step === "days"){
        moment_datetime.set({hour:0,minute:0,second:0,millisecond:0})
      }
      else if (step === "weeks"){
        if(start_date &&
          moment_datetime.startOf('week').isBefore(moment.unix(start_date)))
        {
          moment_datetime = moment_datetime.startOf('day')
        }
        else {
          moment_datetime = moment_datetime.startOf('week')
        }
      }
      else if (step === "years"){
        moment_datetime = moment_datetime.startOf('year')
      }
      let datetime_rounded = moment_datetime.unix()
      const icon = weather_entry['icon_id']
      if (!(datetime_rounded in agregated_weather)){
        agregated_weather[datetime_rounded] = {...weather_entry}
        agregated_weather[datetime_rounded]["count"] = 1
        agregated_weather[datetime_rounded]["icons"] = {}
        agregated_weather[datetime_rounded]["icons"][icon] = 1
        agregated_weather[datetime_rounded]["precipitations"] = weather_entry["rain_1h"] +
        weather_entry["snow_1h"]
      }
      else {
        agregated_weather[datetime_rounded]["count"] +=1
        agregated_weather[datetime_rounded]["clouds"] += weather_entry["clouds"]
        agregated_weather[datetime_rounded]["rain_1h"] += weather_entry["rain_1h"]
        agregated_weather[datetime_rounded]["snow_1h"] += weather_entry["snow_1h"]
        agregated_weather[datetime_rounded]["precipitations"] += weather_entry["snow_1h"] +
          weather_entry["rain_1h"]
        agregated_weather[datetime_rounded]["temp"] += weather_entry["temp"]
        agregated_weather[datetime_rounded]["wind_speed"] += weather_entry["wind_speed"]
        if (!(icon in agregated_weather[datetime_rounded]["icons"])){
        agregated_weather[datetime_rounded]["icons"][icon] = 1
        } else {
        agregated_weather[datetime_rounded]["icons"][icon] += 1
        }
      }
    }
    i+=1
  }
  return Object.values(agregated_weather).map(el => {
    el["temp"] /= el["count"];
    el["clouds"] /= el["count"];
    el["icon"] = Object.keys(el['icons']).reduce((a, b) => el['icons'][a]
      > el['icons'][b] ? a : b);
    return el
  })
}

/**
* Receives an availability array and updates one or several entries
*   depending on the down period [d1, d2], the start of the whole period
*   and the step.
*
* @param {Array} availability
* @param {timestamp} d1 - first item of down period
* @param {timestamp} d2 - second item of down period
* @param {timestamp} start - timestamp of the start of the whole period
* @param {number} step - step of the array in number of seconds (hour, day, week, year)
* @return {Array} - updated availability
*/
const setAvailability = (availability, d1, d2, start, step, period = null, selected_start = null,
  selected_end = null, last_day = null) => {
  // We first compute the relative indices of the first and second dates in the array
  let first_index = Math.floor((d1 - start) / step)
  let second_index = Math.floor((d2 - start) / step)
  let last_index = availability.length-1
  let period_step = step
  let first_step = step
  let second_step = step
  let trunc_case = (period == "weeks" || period == "years") && (
    start !== selected_start || selected_end !== last_day)
  let truncated_first_step = (parseInt(start) + step - parseInt(selected_start) )
  let truncated_last_step = (parseInt(selected_end) - (parseInt(start) + step * last_index))
  if (trunc_case){
    if (first_index === 0){
      first_step = truncated_first_step
    }
    if (second_index === last_index){
      second_step = truncated_last_step
    }
  }
  if (second_index === first_index) {
  // If both of the indices are the same, we have to compute the percentage of the down period
  // to be deducted from the same index. (d1-d2)/step*100
    if (trunc_case && last_index === 0)
      period_step = parseInt(selected_end) - parseInt(selected_start)
    else if (trunc_case && first_index === 0)
      period_step = truncated_first_step
    else  if (trunc_case && second_index === last_index)
      period_step = truncated_last_step
    availability[first_index] = Math.max(0, availability[first_index] - (d2 - d1) / period_step * 100)
    return availability
  }
  else if (second_index === first_index + 1) {

    // If the indices are next to each other, a percentage has to be deducted from each of the
    // positions. (end_first_period - d1)/step*100 and (d2 - end_first_period)/step*100
    availability[first_index] = Math.max(0, availability[first_index] -
                            (second_index * step - (d1 - start))
                            / first_step * 100)
    availability[second_index] = Math.max(0, availability[second_index]-
                            (d2 - start - second_index * step) / second_step * 100)
    return availability
  }
  else {

    // If the indices are distant from each other, the last and first indices have to be updated
    // by deducting the percentage same as the second if, and the indices between have to be 0.
    let delta = second_index - first_index
    availability[first_index] = Math.max(0, availability[first_index] -
                                ((first_index + 1) * first_step - (d1 - start)) / first_step * 100)
    availability[second_index] = Math.max(0, availability[second_index]
                              - (d2 - start - second_index * second_step) / second_step * 100)
    for (var v = first_index + 1; v < second_index; v++) {
      availability[v] = 0
      if (v === second_index - 1) {
        return availability
      }
    }
  }
}
/**
* Fetches the latest cameras snaps for a list of devices
*
* @param {Array} devices - array of devices to fetch
* @param {Array} old_snapshots - array of the loaded snapshots
*
* Dispatches to SET_SNAP_LOADING and SET_SNAPSHOTS
*/
export const getSnapshot = (devices, old_snapshots) => (dispatch, getState) => {
  if (devices && devices.length) {
    const config = getConfig(getState())
    config["responseType"] = "blob"

    let len = devices.length
    let snapshots = {}
    devices.forEach((device, index) => {
      dispatch({
        type: SET_SNAP_LOADING,
        payload: true
      });
      //Only fetch the snaps of the devices that aren't already loaded
      if (!old_snapshots || !Object.keys(old_snapshots).length ||
      !Object.keys(old_snapshots).includes("" + device.value)) {
        APIFactory
          .get("/v1/analyses/" + device.value + "/snap/latest?blur=False", config)
          .then(res => {
            snapshots[device.value] = res.data
            if (index == len - 1) {
              dispatch({
                type: SET_SNAPSHOTS,
                payload: snapshots
              });
            }
          })
          .catch(err => {
            dispatch({
              type: SET_SNAP_LOADING,
              payload: false
            });
          });
      } else {
        snapshots[device.value] = old_snapshots[device.value]
        dispatch({
              type: SET_SNAP_LOADING,
              payload: false
        });
      }
    }
    )
  }
}

/**
* Gets the tracking data of a list of devices between two dates. The method
* checks if the data was already loaded before doing the API call for the JSON_ARRAY
* or JSON formats. The method also fetches the device categories' colors after
* response.
* TODO: Has to be broken down into several parts. API calls have to be disjoined.
*
* @param {moment} start - start of the period to be loaded
* @param {moment} end - end of the period to be loaded
* @param {Array} devices - list of the devices to be loaded
* @param {string} format - Format of data (JSON, JSON_ARRAY, CSV)
* @param {Array} all_devices_selected - List of the selected devices
* @param {Map} old_tracking_data - Loaded tracking data
* @param {Map} old_periods - Map of the lists of the periods already loaded
*
* Dispatches to SET_TRACKING_DATA, SET_TRACKS_LOADING, SET_COLORS and SET_TRACKS_DOWNLOADING
*/
export const getTrackingDataByPeriod = (start, end, devices, format
  , all_devices_selected, old_tracking_data, old_periods) => (dispatch, getState) => {
  if (devices && devices.length) {
    var offset = new Date().getTimezoneOffset();
    var abs_offset = Math.abs(offset)
    var tz = ("" + (Math.floor(abs_offset / 60))).padStart(2, "0") +
       ":" + ("" + (abs_offset % 60)).padStart(2, "0")
    var tzs = offset < 0 ? "+" : "-"
    const config = getConfig(getState())
    const body = {}
    if (format == "csv") {
      config["responseType"] = "blob"
      body["language"] = i18next.language
      dispatch({
        type: SET_TRACKS_DOWNLOADING,
        payload: true
      });
    }
    else {
      dispatch({
        type: SET_TRACKS_LOADING,
        payload: true
      });
    }

    let time_zones = {}
    let tracking_data = {}
    let calls_made = 0
    let obj = {}
    obj["loaded_periods"] = {}
    obj["all_devices_selected"] = all_devices_selected
    let moment_start = moment(start)
    let moment_end = moment(end)
    devices.forEach((device, index) => {
      let new_moment_start = moment(start)
      let new_moment_end = moment(end)
      if (old_periods && old_periods[device.value]) {
        // if start of new period is before end of old period
        if (moment_start.isSameOrAfter(old_periods[device.value].start)
          && moment_start.isBefore(old_periods[device.value].end)) {
          //  end of new period is after end of old period
          if (moment_end.isAfter(old_periods[device.value].end)) {
            // Change new start by old end
            new_moment_start = old_periods[device.value].end.add("seconds", 1)
          }
          else {
          }
        }
        // else if end of new period is after start of old period
        else if (moment_end.isSameOrBefore(old_periods[device.value].end)
          && moment_end.isAfter(old_periods[device.value].start)) {
          //  start of new period is before start of old period
          if (moment_start.isBefore(old_periods[device.value].start)) {
            // Change new end by old start
            new_moment_end = old_periods[device.value].start.subtract("seconds", 1)
          }
          else {
          }
        }
      } else if (format=='JSON_ARRAY'){

        dispatch({
          type: CLEAR_DATA
        });
      }
      body["start"] = new_moment_start.utcOffset(tzs+tz).format("YYYY-MM-DDTHH:mm:ss"+tzs+tz)
      // body["start"] = new_moment_start.format("YYYY-MM-DDTHH:mm:ss"+tzs+tz)
      body["end"] = new_moment_end.utcOffset(tzs+tz).format("YYYY-MM-DDTHH:mm:ss"+tzs+tz)
      APIFactory
        .post("/get_data?format=" + format + "&database=" + device.value +
          "&product=tracking", body, config)
        .then(res => {
          var results;
          if(sessionStorage.getItem("token")){
          if(format === "JSON_ARRAY")
           {
            if (res.data.results){
              results = res.data.results
            }
            else {
              results = res.data
            }
            const last_timezone = results[results.length-1][0].substr(19, 6) // results[results.length-1][0].substr(19, 6)
            time_zones[device.value]=last_timezone
            tracking_data[device.value]=
            results.map(function (x) {
              let m = moment(x[0].substr(0,19)+last_timezone)
              return {
                   timestamp : m.format("X"),
                   tz : x[0].substr(19, 6),
                   //tz : "+00:00", //x[0].substr(19, 6),
                   category : i18next.t(x[1].toLowerCase()),
                   zone_in  : x[2],
                   zone_out : x[3],
                   obj_idx : x[4],
                  }
            } )
              if (old_tracking_data && old_tracking_data[device.value]) {
                if (new_moment_start.isSame(old_periods[device.value]
                    .end.add(1, "seconds"))) {
                  tracking_data[device.value] = old_tracking_data[device.value]
                    .concat(tracking_data[device.value])
                  obj["loaded_periods"][device.value] = {
                    "start": old_periods[device.value].start,
                    "end": new_moment_end
                  }
                }
                else if (new_moment_end.isSame(old_periods[device.value].start
                    .subtract(1, "seconds"))) {
                  tracking_data[device.value] = tracking_data[device.value]
                    .concat(old_tracking_data[device.value])
                  obj["loaded_periods"][device.value] = {
                    "start": new_moment_start,
                    "end": old_periods[device.value].end
                  }
                }
                else {
                  obj["loaded_periods"][device.value] = {
                    "start": moment_start,
                    "end": moment_end
                  }
                }
              }
              else {
                obj["loaded_periods"][device.value] = {
                  "start": moment_start,
                  "end": moment_end
                }
              }
              calls_made += 1
              let colors = {}
              if (calls_made == devices.length) {
                let keys = Array.from(new Set(Object.values(tracking_data)
                  .map((tracks) => { return tracks.map(element => element.category) }).flat()))
                if (keys && keys.length) {
                  const body = {
                    "objects": keys,
                  }
                  obj["tracking_data"] = tracking_data
                  obj["time_zones"]=time_zones
                  obj["selected_devices"]=devices
                  APIFactory
                    .post("/get_colors", body, getConfig(getState()))
                    .then(res => {
                      if (getState().auth.token) {
                        keys.forEach((key, index) => {
                          let rgb = res.data.colors[index]
                          colors[key] = `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`
                          if (index == keys.length - 1) {
                            dispatch({
                              type: SET_TRACKING_DATA,
                              payload: obj
                            });
                            dispatch({
                              type: SET_COLORS,
                              payload: colors
                            })
                          }
                        }
                        )
                      }
                    })
                    .catch(err => {
                      dispatch({
                        type: SET_TRACKING_DATA,
                        payload: obj
                      });
                    });
                } else {
                  obj["time_zones"]=time_zones
                  obj["tracking_data"] = tracking_data
                  obj["selected_devices"]=devices
                  dispatch({
                    type: SET_TRACKING_DATA,
                    payload: obj
                  });
                }
                dispatch({
                  type: SET_TRACKS_LOADING,
                  payload: false
                });
              }
            }
            else {
              dispatch({
                type: SET_TRACKS_DOWNLOADING,
                payload: false
              });
              FileSaver.saveAs(res.data, "data_" + devices.map(device => device.label)
                .join("_") + "_"
                + moment(start).format() + "_" + moment(end).format() + ".csv");
            }
          }

        })

        .catch(err => {
          if (sessionStorage.getItem("token")) {
            if(err && err.response && err.response.data){
              let error_type = err.response.data.error_type
              if(error_type === "before_deployment"){
                let first_date = err.response.data.additional_info ?
                  err.response.data.additional_info.split('T')[0] : ''
                dispatch(returnErrors(i18next.t(error_type)+
                  first_date, 200));
              }
              else{
                dispatch(returnErrors(i18next.t(error_type), 200));
              }
            }
            else{
              dispatch(returnErrors(i18next.t("noDataAvailable"), 200));
            }
          }
          dispatch({
            type: SET_TRACKS_LOADING,
            payload: false
          });
          dispatch({
            type: SET_TRACKS_DOWNLOADING,
            payload: false
          });

        });

    })
  }
};

/**
* Gets the weather history of a device between two periods
*
* @param {moment/timestamp} start - start of the period to be loaded
* @param {moment/timestamp} end - end of the period to be loaded
* @param {Array} devices - list of devices to be loaded
*
* Dispatches to SET_WEATHER_HISTORY
*/

export const getWeatherHistory = (start, end, devices) => (dispatch, getState) => {
  dispatch({
    type: CLEAR_SELECTED_WEATHER
  })
  // Get extra data to avoid time zone problems
  let new_moment_start = moment(start)
  new_moment_start.subtract(1, 'day')
  let new_moment_end = moment(end)
  new_moment_end.add(1, 'day')
  var offset = new Date().getTimezoneOffset();
  var abs_offset = Math.abs(offset)
  var tz = ("" + (Math.floor(abs_offset / 60))).padStart(2, "0") +
     ":" + ("" + (abs_offset % 60)).padStart(2, "0")
  var tzs = offset < 0 ? "+" : "-"
  if (devices && devices.length) {
  devices.forEach((device, index) => {
      const body = {
        "start": new_moment_start.format("YYYY-MM-DDTHH:mm:ss" + tzs + tz),
        "end": new_moment_end.format("YYYY-MM-DDTHH:mm:ss" + tzs + tz)
      }

      APIFactory
        .get("/get_weather_by_device?database=" + device.device_id + "&start="
        + encodeURIComponent(body["start"]) + "&end="
          + encodeURIComponent(body["end"]), getConfig(getState()))
        .then(res => {
          let hourly_weather = res.data.map(weather => {
            weather['time_zone'] = Number(weather['time_zone']);
            weather['precipitations'] = weather['snow_1h'] + weather['rain_1h'];
            return weather
          })
          let daily_weather = agregate_weather(hourly_weather, "days", 6, 22)
          let weekly_weather = agregate_weather(hourly_weather, "weeks", 6, 22,
          res.data.length ? res.data[0]['datetime']:null)
          let yearly_weather = agregate_weather(hourly_weather, "years", 6, 22)
          if (hourly_weather.length){
            hourly_weather.push({
              'datetime':hourly_weather[hourly_weather.length - 1]['datetime'] + 3600,
              'precipitations':null,
              'temp': null,
              'time_zone': hourly_weather[hourly_weather.length - 1]['time_zone']
            })
          }
          dispatch({
            type: SET_WEATHER_HISTORY,
            payload: {
              "hourly_weather": hourly_weather,
              "daily_weather": daily_weather,
              "weekly_weather": weekly_weather,
              "yearly_weather": yearly_weather,
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    })
  }
}
/**
* Gets the periods where the device tracking is unavailable. The loaded
*  format is in "Down periods" [[start,end],[start,end]..]
* The array is then transformed into hourly, daily, weekly and yearly
*  availabilities.
* Uses setAvailability()
*
* @param {moment/timestamp} start - start of the period to be loaded
* @param {moment/timestamp} end - end of the period to be loaded
* @param {Array} devices - list of devices to be loaded
*
* Dispatches to SET_AVAILABILITY
*/

export const getDownPeriodsByPeriod = (start, end, devices) => (dispatch, getState) => {
  let new_moment_start = moment(start)
  new_moment_start.subtract(1, 'day')
  let new_moment_end = moment(end)
  new_moment_end.add(1, 'day')
  var offset = new Date().getTimezoneOffset();
  var abs_offset = Math.abs(offset)
  var tz = ("" + (Math.floor(abs_offset / 60))).padStart(2, "0") +
     ":" + ("" + (abs_offset % 60)).padStart(2, "0")
  var tzs = offset < 0 ? "+" : "-"
  if (devices && devices.length) {
  devices.forEach((device, index) => {
      const body = {
        "start": new_moment_start.format("YYYY-MM-DDTHH:mm:ss" + tzs + tz),
        "end": new_moment_end.format("YYYY-MM-DDTHH:mm:ss" + tzs + tz)
      }
      APIFactory
        .get("/get_down_periods?database=" + device.id + "&start="
        + encodeURIComponent(body["start"]) + "&end="
          + encodeURIComponent(body["end"]), getConfig(getState()))
        .then(res => {
          if (res.data){
            const h = 3600000
            const d = h * 24
            const w = d * 7
            const periods = res.data
            const n_periods = res.data.length
            const last_timezone = n_periods ? periods[periods.length-1][1].substr(19, 6) : ""
            const isDSTInterval = new_moment_start.isDST() !== new_moment_end.isDST()
            const number_hours_float = (new_moment_end - new_moment_start) / h
            if (isDSTInterval && new_moment_start.isDST()){
              new_moment_start.add(1,'h')
            } else if (isDSTInterval) {
              new_moment_start.subtract(1,'h')
            }
            const y_leap = d * 366
            const y_non_leap = d * 365
            const number_of_days_in_year = new_moment_start.isLeapYear() &&
              new_moment_end.isLeapYear() ? 366 : 365

            const y = d * number_of_days_in_year
            // We add a day to the year if all of the period is in a leap year
            const start_weeks = moment(new_moment_start)
            const end_weeks = moment(new_moment_end)
            const start_years = moment(new_moment_start)
            const end_years = moment(new_moment_end)
            start_weeks.startOf('week').isoWeekday(1)
            end_weeks.endOf('week').isoWeekday(1)
            start_years.startOf('year')
            end_years.endOf('year')
            const moment_start_timestamp = new_moment_start.format("X")
            const moment_end_timestamp = new_moment_end.format("X")
            const first_day_of_week_timestamp = start_weeks.format('X')
            const last_day_of_week_timestamp = end_weeks.format('X')
            const first_day_of_year_timestamp = start_years.format('X')
            const last_day_of_year_timestamp = end_years.format('X')
            let n_hours = Math.ceil(number_hours_float)
            let n_days = isDSTInterval?Math.round(n_hours / 24):Math.ceil(n_hours / 24)
            let weeks_diff = new_moment_end.diff(start_weeks, 'weeks', true)
            let n_weeks = Math.ceil(weeks_diff)
            // let n_years = Math.ceil(n_days / number_of_days_in_year)
            let n_years = Math.ceil(new_moment_end.diff(start_years, 'years', true))
            let hourly_availability = Array(n_hours).fill(100);
            let daily_availability = Array(n_days).fill(100)
            let weekly_availability = Array(n_weeks).fill(100)
            let yearly_availability = Array(n_years).fill(100)
            let x = 0
            const total_length = periods.length

            periods.forEach(p => {
              let d1_moment = moment(p[0].substr(0,19)+last_timezone)
              let d1 = moment(d1_moment).format("X")
              let d2 = moment(p[1].substr(0,19)+last_timezone).format("X")

              x += 1
              // Fill in the hours
              hourly_availability = setAvailability(hourly_availability, d1, d2,
                  moment_start_timestamp, h/1000)
              // Fill in the days
              daily_availability = setAvailability(daily_availability, d1, d2,
                 moment_start_timestamp, d/1000)
              // Fill in the weeks
              weekly_availability = setAvailability(weekly_availability, d1, d2,
                  first_day_of_week_timestamp, w/1000, "weeks",
                  moment_start_timestamp, moment_end_timestamp,
                  last_day_of_week_timestamp)
              // Fill in the years
              yearly_availability = setAvailability(yearly_availability, d1, d2,
                first_day_of_year_timestamp,
                d1_moment.isLeapYear() ? y_leap/1000 : y_non_leap/1000
                , "years",
                moment_start_timestamp, moment_end_timestamp,
                last_day_of_year_timestamp
                )

          })
          dispatch({
            type: SET_AVAILABILITY,
            payload: {
              "hourly_availability": hourly_availability.map((value, index) => {
                return {
                  "x": String(index * h + new_moment_start.utcOffset(last_timezone)),
                  "y": value
                }
              }),
              "daily_availability": daily_availability.map((value, index) => {
                return {
                  "x": String(index * d + new_moment_start.utcOffset(last_timezone)),
                  "y": value
                }
              }),
              "weekly_availability": weekly_availability.map((value, index) => {
                return {
                  "x": String(index * w + start_weeks.utcOffset(last_timezone)),
                  "y": value
                }
              }),
              "yearly_availability": yearly_availability.map((value, index) => {
                return {
                  "x": String(
                    index > 0 ?
                    moment(new_moment_start)
                    .startOf('year')
                    .add(index, 'year')
                    .utcOffset(last_timezone)
                    .format('X')
                    * 1000
                    :
                    moment(new_moment_start)
                    .utcOffset(last_timezone)
                    .format('X')
                    * 1000
                    // new_moment_start.utcOffset(last_timezone))
                  )
                  ,
                  "y": value
                }
              }),
            }
          })
          } else {
            dispatch({
              type: SET_AVAILABILITY,
              payload: {
                "hourly_availability": [],
                "daily_availability": [],
                "weekly_availability": [],
                "yearly_availability": [],
              }
            })
          }
        })
        .catch(err => {
          // console.log(err)
        })

    })
  }
}

/**
* Sets the colors of the categories of devices.
*
* @param {Map} colors - colors map of the devices
*
* Dispatches to SET_COLORS
*/
export const setColors = (colors) => dispatch => {
  dispatch({
    type: SET_COLORS,
    payload: colors
  })
}


/**
* [Obsolete and Unsed]
* Gets colors of a list of categories from the API.
*
* @param {Array} categories
*/
export const getColors = (categories) => (dispatch, getState) => {
  const body = {
    "objects": categories,
  }
  dispatch({
    type: SET_COLORS_LOADING,
    payload: true
  });
  APIFactory
    .post("/get_colors", body, getConfig(getState()))
    .then(res => {
      if (sessionStorage.getItem("token")) {
        dispatch({
          type: SET_COLORS,
          payload: Object.assign(...res.data.colors.map((rgb, index) =>
           ({ [categories[index]]: "rgb(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ")" })))
        });
      }
    })
    .catch(err => {
      if (sessionStorage.getItem("token")) {
        dispatch({
          type: SET_COLORS_LOADING,
          payload: false
        });
      }

    });
};

/**
* Gets the coordinates of the devices owned by the user. The user is
* identified by the token. The coordinates are in Latlong.
* Important Issue: Lat and Long are inverted in the database.
*
* @param {Array} categories
*
* Dispatches to SET_COORDINATES
*/
export const getDeviceCoordinates = () => (dispatch, getState) => {
  APIFactory
    .get("/get_coordinates_by_user", getConfig(getState()))
    .then(res => {
      if (sessionStorage.getItem("token")) {
        dispatch({
          type: SET_COORDINATES,
          payload: res.data.results ? res.data.results : res.data
        });
      }
    })
    .catch(err => {
      if (sessionStorage.getItem("token")) {
        console.log(err)
      }

    });
};

/**
* This is used when devices are selected and are already loaded, devices_values
*   are the only devices kept, the others are discarded and deleted from the store.
*
* @param {Array} devices_values ids of the devices to be kept
*
* Dispatches to KEEP_DEVICES
*/
export const keepDevices = (devices_values) => dispatch => {
  dispatch({
    type: KEEP_DEVICES,
    payload: devices_values
  })
}

/**
* Sets the selected step in the redux store.
*
* @param {number} step
*
* Dispatches to SET_STEP
*/
export const setStep = (step) => dispatch => {
  dispatch({
    type: SET_STEP,
    payload: step
  })
}

/**
* Sets the available period of the selected device. The available period
* contains the actual start and end of the data of a device. This period
* can be smaller than the selected period.
*
* @param {moment} start
* @param {moment} end
*
* Dispatches to SET_AVAILABLE_PERIOD
*/
export const setAvailablePeriod = (start, end) => dispatch => {
  dispatch({
    type: SET_AVAILABLE_PERIOD,
    payload: [start, end]

  })
}

export const setBarGraphDimensions = (width, offset) => dispatch => {
  dispatch({
    type: SET_BAR_GRAPH_DIMENSIONS,
    payload: {"width":width, "offset": offset}
  })
}

/**
* Sets the selected period as it is from the Select element.
*
* @param {moment} start
* @param {moment} end
*
* Dispatches to SET_SELECTED_PERIOD
*/
export const setSelectedPeriod = (start, end) => dispatch => {
  dispatch({
    type: SET_SELECTED_PERIOD,
    payload: [start, end]
  })
}

/**
* Sets updates the categories of the tracking data by translating them.
* This is triggered on i18n change in profile.
*
*
* Dispatches to SET_SELECTED_PERIOD
*/
export const updateTrackingDataCategories = () => (dispatch, getState) => {
  let updated_data = {}
  let data = getState().tracking_data.tracking_data
  const keys = Object.keys(data)
  for (const device of keys){
    if (device){
      updated_data[device] = []
      for (const entry of Object.values(data[device])){
        updated_data[device].push(entry)
        let category = entry.category
        updated_data[device][updated_data[device].length - 1].category
          = i18next.t(category.toLowerCase())
      }
    }
  }
  dispatch({
    type: SET_UPDATED_DATA,
    payload: updated_data
  })
}

/**
* Sets period of the trackbar.
* TODO: further document this, what's the difference with the above available periods
*
* @param {moment} start
* @param {moment} end
*
* Dispatches to SET_ACTUAL_TRACK_PERIOD
*/
export const setActualTrackbarPeriod = (start, end) => dispatch => {
  dispatch({
    type: SET_ACTUAL_TRACK_PERIOD,
    payload: [start, end]
  })
}
