/* eslint-disable */
import React, { Component, Fragment } from 'react'
import moment from "moment";

import {
  Row,
  Col,
  Container
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle as farQuestionCircle}  from '@fortawesome/free-regular-svg-icons'
import { connect } from 'react-redux'
import { ResponsiveLine } from '@nivo/line'
import { withTranslation } from "react-i18next";
import { isLegacyEdge } from "react-device-detect";

var index = 'timestamp'


const formatStat = (stat) => {
  return (Math.trunc(stat*10)/10).toFixed(1)
}

const ticksFormatter = (value, data) => {
  const valueIndex = data.map((i) => i.x).indexOf(value);
  const data_len = data.length;
  if (
    valueIndex % (Math.ceil(data_len / 15) * Math.floor(value.length / 4)) === 0
  ) {
    return value;
  }
  return "";
};



const ResponsiveLinePlotTemp = ({ data, index, max_ticks = 5, graph_height = 220, step = "days",
timezone="+02:00", t, first_offset=0}) => {
  return     <ResponsiveLine
  data={[
      {
          id: t('temperature'),
          color: 'white',
          data: data
      }
  ]}

  xScale = {{ type: "point"}}
  colors = {"rgb(252, 216, 5)"}
  yScale={{
      type: 'linear',
      stacked: false,
      max: Math.max.apply(null, data.map(element => element.y))+5,
      min: Math.min.apply(null, data.map(element => element.y))-2
  }}
  theme={{
    axis: {
      ticks: {
        text: {
          fill: "white",
          fontWeight: "bold"
        }
      },
      legend: {
        text: {
          fill: "white"
        }
      }
    },
    tooltip: {
      container: {
        background: 'var(--fill_2)',
      }
    },
    legends: {
      text: {
        fill: 'white'
      }
    }
  }}
  axisLeft={{
    format: value => value+"°C",
    legendOffset: 5
  }}
  axisBottom={{
    tickSize: 0,
    tickPadding: isLegacyEdge ? 15 : 5,
    tickRotation: 0,
    legend: null,
    legendPosition: 'middle',
    legendOffset: 32,
    format: value => ticksFormatter(value, data),
    tickValues : 3
  }}

  legends={[
    {
      anchor: "top",
      direction: "row",
      justify: false,
      translateX: -50,
      translateY: -42,
      itemsSpacing: 2,
      itemWidth: 90,
      itemHeight: 20,
      itemDirection: "left-to-right",
      itemOpacity: 1,
      symbolSize: 14,
      effects: [
        {
          on: "hover",
          style: {
            itemOpacity: 1,
          },
        },
      ],
      itemTextColor: "white",
    },
  ]}
  curve={'linear'}
  enablePointLabel={false}
  pointSize={data.length < 8 ? 8 :4}
  useMesh={false}
  enableSlices={false}
  enableGridX={false}
  enableGridY={true}
  height= {320}
  margin={{ top: 50, right: 48.25, bottom: 40, left: 39.14 + first_offset }}
  padding={data.length === 1 ? 0.8 : data.length === 2 ? 0.5 : data.length === 3 ? 0.3 : 0.1}
  animate = {false}
  tooltip={({ point }) => {
    return (
        <div
            style={{
                background: 'var(--fill_2)',
                padding: '6px 12px',
                color: 'var(--wintics_yellow)'
                // border: '1px solid #ccc',
            }}
            >
            <strong>
              <div>{t('date')}: {point.data.xFormatted}</div>
              <div>{t('weather')}: {Number(point.data.yFormatted).toFixed(1)}</div>
            </strong>
          </div>
    )
}}
/>
}


const ResponsiveLinePlotPrecip = ({ data, index, max_ticks = 5, graph_height = 220, step = "days",
timezone="+02:00", t, first_offset = 0}) => {
  return     <ResponsiveLine
  data={data}

  xScale={{ type: "point"}}
  colors={[
    "var(--precipitations_color)",
    "rgba(255, 255, 255, 0)"
  ]}
  yScale={{
      type: 'linear',
      stacked: false,
      max: Math.max.apply(null, data[0].data.map(element => element.y))+15,
      min: 0
  }}
  theme={{
    axis: {
      ticks: {
        text: {
          fill: "white",
          fontWeight: "bold"
        }
      },
      legend: {
        text: {
          fill: "white"
        }
      }
    },
    tooltip: {
      container: {
        background: 'var(--fill_2)',
      }
    },
    legends: {
      text: {
        fill: 'white'
      }
    }
  }}
  axisLeft={null}
  axisRight={{
    format: value => value+"mm",
    legendOffset: 2,
    tickSize: 4,
    tickPadding: 2
  }}
  axisBottom={null}
  curve={'linear'}
  enablePointLabel={false}
  pointSize={data[0].data.length < 8 ? 8 :4}
  useMesh={true}
  enableSlices={false}
  enableGridX={false}
  enableGridY={false}
  height= {320}
  margin={{ top: 50, right: 48.25, bottom: 40, left: 39.14 + first_offset }}
  padding={data[0].data.length === 1 ? 0.8 : data[0].data.length === 2 ? 0.5
    : data[0].data.length === 3 ? 0.3 : 0.1}
  animate = {false}
  markers={[
    {
      axis: 'y',
      value: 0,
      lineStyle: {
        strokeWidth: data[0].data.length ? 1.5 : 0,
        stroke: 'rgb(200, 200, 200)'
      },
      legend: '',
      legendOrientation: 'horizental',
    }
  ]}
  legends={[
    {
      anchor: "top",
      direction: "row",
      justify: false,
      translateX: 10,
      translateY: -42,
      itemsSpacing: 2,
      itemWidth: 90,
      itemHeight: 20,
      itemDirection: "left-to-right",
      itemOpacity: 1,
      symbolSize: 14,
      effects: [
        {
          on: "hover",
          style: {
            itemOpacity: 1,
          },
        },
      ],
    },
  ]}
  enableSlices="x"
  sliceTooltip={({ slice }) => {
    return (
      <div
      style={{
          background: 'var(--fill_2)',
          padding: '6px 12px',
          color: 'white'
      }}
      >
        <strong>
          <div>{t('date')}: {slice.points[0].data.xFormatted}</div>
          <div style={{color: 'var(--wintics_yellow)'}}>
            {t('temperature')}: {Number(slice.points[0].data.yFormatted).toFixed(2)}°C
          </div>
          <div style={{color: 'var(--precipitations_color)'}}>
            {t('precipitations')}: {Number(slice.points[1].data.yFormatted).toFixed(2)}mm
          </div>
        </strong>
      </div>
    );
  }}
/>
}

class WeatherHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      total_temp: 0,
      min_temp: 0,
      max_tem: 0,
      mean_temp: 0,
      sum_temp: 0,
      min_precip: 0,
      max_precip: 0,
      mean_precip: 0,
      sum_precip: 0
    }
  }
  static getDerivedStateFromProps(nextProps, prevState){
    let data = nextProps.data

    let max_temp = 0, min_temp = Number.MAX_SAFE_INTEGER,
       mean_temp = 0, sum_temp = 0, len = data.length;
    let max_precip = 0, min_precip = Number.MAX_SAFE_INTEGER,
      mean_precip = 0, sum_precip = 0;

    data.forEach((element, index) => {
      const temp = element.y, precip = element.precipitations
      if (temp != null && precip != null){
        max_temp = Math.max(temp, max_temp)
        max_precip = Math.max(precip, max_precip)
        min_temp = Math.min(temp, min_temp)
        min_precip = Math.min(precip, min_precip)
        sum_temp += temp
        sum_precip += precip
        if (index === len - 1) {
          mean_temp = sum_temp / len
          mean_precip = sum_precip / len
        }
      }
    })
    return {
      min_temp : min_temp == Number.MAX_SAFE_INTEGER ? 0: min_temp,
      min_precip : min_precip == Number.MAX_SAFE_INTEGER ? 0: min_precip,
      max_temp : max_temp,
      max_precip : max_precip,
      mean_temp : mean_temp,
      mean_precip : mean_precip,
      sum_temp : sum_temp,
      sum_precip : sum_precip
    };
  }

    render() {
      const { t, total_width, first_offset } = this.props;
      if (this.props.data && this.props.data.length) {
      return (
      <Container className="ml-0 pr-0 mb-3">


        <Row className="row-stats">
            <span className="center-subtitle">
              <span >{t('min')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {formatStat(this.state.min_temp)}°C</span>
              <span >{t('average')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {formatStat(this.state.mean_temp)}°C</span>
              <span >{t('max')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {formatStat(this.state.max_temp)}°C</span>
            </span>
            <span className="pull-right">
              <span >{t('min')}:</span><span className="ml-2 mr-4 font-weight-bold
              title-yellow center-subtitle" style={{color: 'var(--precipitations_color)'}}>
                {formatStat(this.state.min_precip)}mm</span>
              <span >{t('average')}:</span><span className="ml-2 mr-4 font-weight-bold
              title-yellow center-subtitle" style={{color: 'var(--precipitations_color)'}}>
                {formatStat(this.state.mean_precip)}mm</span>
              <span >{t('max')}:</span><span className="ml-2 mr-0 font-weight-bold
              title-yellow center-subtitle" style={{color: 'var(--precipitations_color)'}}>
                {formatStat(this.state.max_precip)}mm</span>
            </span>
        </Row>
          {/* <Col> */}
        <div className="graphsParentWrapper">
        <Row className="trackBarAvailabilityDiv firstGraphContainer">
          <div style={{width: (total_width + first_offset + 87.39) + "px"}}>
          <ResponsiveLinePlotTemp key={"temp_graph"} data={this.props.data}
            index={index} max_ticks={this.props.max_ticks}
            graph_height={this.props.graph_height} step={this.props.step}
            timezone = {this.props.timezone} t={t} first_offset={first_offset}
          /></div>
          </Row>
        <Row className="trackBarAvailabilityDiv graphContainer">
          <div style={{width: (total_width + first_offset + 87.39) + "px"}}>
          <ResponsiveLinePlotPrecip key={"precipitations_graph"}

          data={[{
              'id': t("precipitations"),
              'color': "var(--precipitations_color)",
              'data': this.props.data.map(
                weather => {return {'x':weather['x'],'y':weather['precipitations']}}
              )
              },
              {
                'id': " ",
                'color': "var(--wintics_yellow)",
                'data': this.props.data
              }
              ]
            }
            index={index} max_ticks={this.props.max_ticks}
            graph_height={this.props.graph_height} step={this.props.step}
            timezone = {this.props.timezone} t={t} first_offset={first_offset}
          /></div>
        </Row>
        </div>
      </Container>
    )
  }
  else{
    return null
  }
  }
}

const mapStateToProps = state => ({
  tracking_data: state.tracking_data,
});


const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WeatherHistory))
