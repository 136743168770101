/* eslint-disable */
import React, { Component, Fragment } from 'react'
import moment from "moment";

import {
  Row,
  Container
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle as farQuestionCircle}  from '@fortawesome/free-regular-svg-icons'
import { connect } from 'react-redux'
import { ResponsiveLine } from '@nivo/line'
import { withTranslation } from "react-i18next";
import { isLegacyEdge } from "react-device-detect";

var index = 'timestamp'
const ticksFormatter = (value, data) => {
  const valueIndex = data.map((i) => i.x).indexOf(value);
  const data_len = data.length;
  if (
    valueIndex % (Math.ceil(data_len / 15) * Math.floor(value.length / 4)) === 0
  ) {
    return value;
  }
  return "";
};

const formatStat = (stat) => {
  return (Math.trunc(stat*10)/10).toFixed(1)
}

const ResponsiveLinePlot = ({ data, index, max_ticks = 5, graph_height = 220, step = "days",
timezone = "+02:00", t, total_width = null, first_offset = null}) => {
  return     <ResponsiveLine
  data={[
      {
          id: t('availability'),
          data: data
      }
  ]}
  xScale = {{ type: "point"}}
  colors = {"rgb(252, 216, 5)"}
  yScale={{
      type: 'linear',
      stacked: false,
      max: 100,
      min: 0
  }}
  theme={{
    axis: {
      ticks: {
        text: {
          fill: "white",
          fontWeight: "bold"
        }
      },
      legend: {
        text: {
          fill: "white"
        }
      }
    },
    tooltip: {
      container: {
        background: 'var(--fill_2)',
      }
    },
    legends: {
      text: {
        fill: 'white'
      }
    }
  }}
  axisLeft={
    //  null
    {
      format: value => value+"%",
      legendOffset: 5
    }
  }
  axisBottom={{
    tickSize: 0,
    tickPadding: isLegacyEdge ? 15 : 5,
    tickRotation: 0,
    legend: null,
    legendPosition: 'middle',
    legendOffset: 32,
    format: value => ticksFormatter(value, data),
    tickValues : 2
    // tickValues : data.length < 16 ? data.length : data.length/2
  }}
  curve={'linear'}
  enablePointLabel={false}
  pointSize={data.length < 8 ? 8 :4}
  pointBorderWidth={1}
  pointBorderColor={{
      from: 'color',
      modifiers: [['darker', 0.3]],
  }}
  useMesh={true}
  enableSlices={false}
  enableGridX={false}
  enableGridY={true}
  height= {320}
  margin={{ top: 50, right: 48.25, bottom: 40, left: 39.14 + first_offset}}
  padding={data.length === 1 ? 0.8 : data.length === 2 ? 0.5 : data.length === 3 ? 0.3 : 0.1}
  animate = {false}
  legends={[
    {
      anchor: "top",
      direction: "row",
      justify: false,
      translateX: 0,
      translateY: -42,
      itemsSpacing: 2,
      itemWidth: 90,
      itemHeight: 20,
      itemDirection: "left-to-right",
      itemOpacity: 1,
      symbolSize: 14,
      effects: [
        {
          on: "hover",
          style: {
            itemOpacity: 1,
          },
        },
      ],
      // itemTextColor: "white",
    },
  ]}
  tooltip={({ point }) => {
    return (
        <div
            style={{
                background: 'var(--fill_2)',
                padding: '6px 12px',
                color: 'var(--wintics_yellow)'
                // border: '1px solid #ccc',
            }}
            >
            <strong>
              <div>{t('date')}: {point.data.xFormatted}</div>
              <div>{t('availability')}: {Number(point.data.yFormatted).toFixed(1)}%</div>
            </strong>
          </div>
    )
}}
/>
}

const periods_to_seconds = { "Hours": 3600, "Days": 86400, "Weeks": 604800, "Years": 3.154e+7 }

class LineAvailability extends Component {
  constructor(props) {
    super(props)
    this.state = {
      total: 0,
      min_: 0,
      max_: 0,
      mean_: 0,
      sum_: 0
    }

  }
  static getDerivedStateFromProps(nextProps, prevState){
    let data = nextProps.data

    let max_ = 0, min_ = Number.MAX_SAFE_INTEGER, mean_ = 0, sum_ = 0, len = data.length

    data.forEach((element, index) => {
      const value = element.y
      max_ = Math.max(value, max_)
      min_ = Math.min(value, min_)
      sum_ += value
      if (index === len - 1) {
        mean_ = sum_ / len
      }
    })
    return {
      min_ : min_,
      max_ : max_,
      mean_ : mean_,
      sum_ : sum_
    };
  }

    render() {
      const { t, total_width, first_offset } = this.props;
      return (
      <Container className="ml-0 pr-0 mb-3">
        {this.state.min_>=0 && this.state.max_>=0 && this.state.mean_>=0 ?
        <Row className="row-stats">
            <span className="center-subtitle">
              <span >{t('min')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {formatStat(this.state.min_)}%</span>
              <span >{t('average')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {formatStat(this.state.mean_)}%</span>
              <span >{t('max')}:</span><span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
                {formatStat(this.state.max_)}%</span>
            </span>
        </Row>
        : null}
          {/* <Col> */}
        <Row className="trackBarAvailabilityDiv" >
          <div style={{width: (total_width + first_offset + 87.39) + "px"}}>
            <ResponsiveLinePlot key={"availability_graph"} data={this.props.data}
              index={index} max_ticks={this.props.max_ticks}
              graph_height={this.props.graph_height} step={this.props.step}
              timezone = {this.props.timezone} t={t}
              first_offset = {first_offset}
              // total_width = {total_width}
            />
          </div>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  tracking_data: state.tracking_data,
});


const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LineAvailability))
