import {CREATE_MESSAGE, HIDE_MESSAGE} from '../actions/types';

const initialState = {
  msg: '',
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case CREATE_MESSAGE:
      return {
        ...state,
        msg: payload,
      };
    case HIDE_MESSAGE:
      return {
        ...state,
        msg: '',
      };
    default:
      return state;
  }
};
