/* eslint-disable max-len */
import React from 'react';

import {
  Row,
  Container,
} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle as farQuestionCircle}
  from '@fortawesome/free-regular-svg-icons';
import {MapContainer, TileLayer, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Markers from './CameraMarkers';
// eslint-disable-next-line camelcase
// import {devices_locs} from '../../../assets/devices_localisations';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
/**
 * Initializes the gps Map
 * @param {*} props
 * @return {null}
 */
function MapHandler(props) {
  const map = useMap();
  map.panTo(props.center);
  return null;
}

const winticsPosition = [48.893450, 2.354669];

const DevicesMap = (props) => {
  const {t} = useTranslation();
  const devices_locs = props.devices
  const selectedDevice = devices_locs && devices_locs.length > 0 ?
  devices_locs.find((device)=> String(device.id) ===
    String(props.selectedIds[0])) : null;
  const position = selectedDevice && selectedDevice.geometry ?
    selectedDevice.geometry : winticsPosition;
  return (
    <Container className="ml-0 pr-0 pl-0 mb-3">
      <Row className="title title-yellow">{t('locations')}
        <span className="ml-2 tooltip">
          <FontAwesomeIcon icon={farQuestionCircle}/>
          <span className="tooltiptext ">
            {t('locationsDescription')}
          </span>
        </span></Row>
      <Row className="row-stats">
        <span className="center-subtitle">
          {selectedDevice && selectedDevice.geometry?
          <span>
            <span >{t('latitude')}:</span>
            <span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
              {selectedDevice.geometry[0]}
            </span>
            <span >{t('longitude')}:</span>
            <span className="ml-2 mr-4 font-weight-bold title-yellow center-subtitle">
              {selectedDevice.geometry[1]}
            </span>
          </span> :
          <></>
          }
        </span>
      </Row>

      <Row>
        <Container className="card-gray zone-map">
          <MapContainer
            center={position} zoom={13} scrollWheelZoom={false}>
            <MapHandler center = {position}/>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>
                contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Markers cameras={props.devices}
              isdisabled={props.button_disabled}
              onClickShowDataPopup={props.onClickShowDataPopup}
              selectedIds={props.selectedIds}/>
          </MapContainer>
        </Container>
      </Row>
    </Container>
  );
};

export default DevicesMap;

DevicesMap.propTypes = {
  devices: PropTypes.array,
  button_disabled: PropTypes.bool,
  selectedIds: PropTypes.array,
};
