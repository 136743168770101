import L from 'leaflet';
export const InactiveCameraLocationIcon = L.icon({
  iconUrl: require('../../../assets/security-camera-inactive.svg'),
  iconRetinaUrl: require('../../../assets/security-camera-inactive.svg'),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: 'leaflet-venue-icon',
});

export const ActiveCameraLocationIcon = L.icon({
  iconUrl: require('../../../assets/security-camera.svg'),
  iconRetinaUrl: require('../../../assets/security-camera.svg'),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [35, 35],
  className: 'leaflet-venue-icon',
});
