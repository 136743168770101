import { CREATE_MESSAGE, GET_ERRORS, CREATE_INFO, HIDE_MESSAGE } from "./types";

// CREATE MESSAGE
export const createMessage = msg => {
  return {
    type: CREATE_MESSAGE,
    payload: msg
  };
};

// RETURN ERRORS
export const returnErrors = (msg, status) => {
  return {
    type: GET_ERRORS,
    payload: { msg, status }
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
}