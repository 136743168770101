/* eslint-disable react/prop-types */
import React, { Component, useState } from 'react'
import DotLoader from 'react-spinners/DotLoader'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

export default function CheckboxWintics(props) {
    const {t} = useTranslation();

    return (
        <div className='checkboxCustom'>

        <label htmlFor={"input"+props.item+props.after_name}
            className="text-smaller">
        <input
                type="checkbox"
                value={props.item}
                inline="true"
                name={"input"+props.item+props.after_name}
                label={props.item}
                onChange={props.handleSelectChange}
                checked={props.selected_items.has(props.item)}
                id={"input"+props.item+props.after_name }
                style={{
                    'height': 0,
                    'width': 0,
                    'position': 'absolute',
                }}
            />
            <FontAwesomeIcon
                className={props.selected_items.has(props.item) ?
                    'checked-category' : 'unchecked-category'}
                icon={['fas', 'circle']}
                style={{
                    'marginRight': '5px',
                }}
            />
            {t(props.item)} </label>
        </div>
)
}